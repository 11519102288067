import React from "react";
import cta_image from "../../_images/cards-arrow.svg";
import { Link } from "@reach/router";

export default function CardBox({ title, body, cta, link }) {
  return (
    <div className="category__card__box">
      <h2>{title}</h2>
      <Link className="card__url" to={link}>
        {cta}
        <span>
          <img src={cta_image} alt={cta} />
        </span>
      </Link>
    </div>
  );
}
