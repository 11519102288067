import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import EventBannerDetail from "../_components/ui/event-banner-detail";
import EventDetail from "../_components/contents/event-detail";
import { eventService } from "../_services/event.service";
import * as moment from "moment";
import { path, url } from "../_helpers";
import Modal from "react-bootstrap/Modal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import ico_toolbar from "../_images/ico-share-toolbar.svg";
import SpeakersEvents from "../_components/lists/speakers-events";
import { SponsorsEvents } from "../_components/lists/sponsors-events";
import RegisterEventForm from "../_components/forms/register-event-form";
import { userService } from "../_services";
import CommunityAd2 from "../_components/ads/community-ad2";
import CommunityAdSingleVideo from "../_components/ads/community-ad-singleVideo";

const EventPage = ({ slug }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [shareUrl, setShareUrl] = useState("");
  const endUrl = shareUrl.split("/fined");
  const newUrl = `${endUrl[0]}/${lang}/${slug}`;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [user, setUser] = useState({});
  const [members, setMembers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [info, setInfo] = useState({
    title: t("contents.loading"),
    field_name_evente: t("contents.loading"),
  });

  const [page, setPage] = useState(0);
  let pageNum = page + 1;

  const handlePagePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handlePageNext = () => {
    if (pageNum < info.speakers.pager.total_pages) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    /** Call Services  */
    eventService
      .getInfoEvent(lang, slug, page)
      .then((res) => res.json())
      .then((response) => {
        setInfo(response[0]);
        setTotalPages(response[0].speakers.pager.total_pages)
        setShareUrl(path(url(response[0].url)));
        membersEvent(lang, response[0].nid);
      })
      .catch((error) => console.log(error));
  }, [lang, slug, setInfo, page]);

  const membersEvent = (lang, idEvent) => {
    eventService
      .getMembersEvent(lang, idEvent)
      .then((res) => res.json())
      .then((response) => {
        setMembers(response);
      })
      .catch((error) => console.log(error));
  };

  const clickShares = (e) => {
    e.preventDefault();
  };

  const { id } = JSON.parse(localStorage.getItem("me"));
  const existMember = members.some((obj) => obj.uid == id);

  useEffect(() => {
    /** Call Services  */
    if (id !== undefined) {
      userService
        .summary(lang, id)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            setUser(response[0]);
          }
        })
        .catch(console.log);

      return () => {
        setUser({});
      };
    }
  }, [lang]);

  return (
    <Layout>
      <SEO lang={lang} title={info.field_name_evente} />
      <main
        className="contents__inner"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
         
        <article itemScope="" itemType="http://schema.org/Article">
          {/* Beginning of top trending section */}
          <section className="main__content event__content">
            <div className="event__headline">
              <div className="category__event">
                <a href="/">Cybersecurity</a>
              </div>
              <EventBannerDetail
                img={path(info.field_image)}
                location={info.field_location}
                startDate={moment(info.field_start_date).format("MMM DD, YYYY")}
                endDate={moment(info.field_end_date).format("MMM DD, YYYY")}
                email={info.field_contact}
                buttonBuy={info.field_tickets}
                name={info.field_name_evente}
                category={info.field_principal_category}
                user={user}
                event={info.nid}
                existMember={existMember}
              />
            </div>
            <CommunityAd2 />
            <div className="details__share container">
              <div className="interesting__btn__alt align-items-center others__links dropdown feed">
                <a
                  href="/"
                  className="d-flex align-items-center"
                  onClick={clickShares}
                >
                  <img src={ico_toolbar} alt="Reply to comment" />
                  <span>{t("contents.share")}</span>
                </a>
                <ul className="share__options dropdown-list" id="shared__list">
                  <div className="share__links">
                    <WhatsappShareButton url={newUrl}>
                      <p>
                        <i className="fab fa-whatsapp" aria-hidden="true"></i>
                        {t("shared.share")} Whatsapp
                      </p>
                    </WhatsappShareButton>
                    <FacebookShareButton url={newUrl}>
                      <p>
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                        {t("shared.share")} Facebook
                      </p>
                    </FacebookShareButton>
                    <LinkedinShareButton url={newUrl}>
                      <p>
                        <i
                          className="fab fa-linkedin-in"
                          aria-hidden="true"
                        ></i>
                        {t("shared.share")} Linkedin
                      </p>
                    </LinkedinShareButton>
                    <TwitterShareButton url={newUrl}>
                      <p>
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                        {t("shared.share")} Twitter
                      </p>
                    </TwitterShareButton>
                  </div>
                </ul>
              </div>
            </div>

            {/* Event detail */}
            <div className="container event__contain d-flex flex-column-reverse flex-sm-column-reverse">
              <EventDetail
                text={info.body}
                categories={info.field_secondary_categories}
                buttonDownload={info.field_diary}
              />
            </div>
          </section>
        </article>

        {/* Speakers events */}
        <section className="speakers_events">
          <SpeakersEvents
            speakers={info.speakers}
            handlePagePrev={handlePagePrev}
            handlePageNext={handlePageNext}
            pageNum={pageNum}
            totalPages={totalPages}

          />
        </section>

        {/* Sponsors events */}
        <section className="sponsors_events container">
          <SponsorsEvents sponsors={info.sponsors} />
        </section>

        {/* Footer events */}
        <section className="footer_events container d-flex justify-content-center flex-column">
          <h1>{t("events.footer_info")}</h1>
          <div className="events__tickets">
            {existMember ? (
              <p>{t("events.registered")}</p>
            ) : (
              <button onClick={() => setShow(true)}>
                {t("register.title")}
              </button>
            )}

            <a href="https://www.allticketscol.com/" target="_blank">
              {t("events.buy_tickets")}
            </a>
          </div>
        </section>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            style={{ border: "none", width: "70%", margin: "0 auto" }}
            closeButton
          ></Modal.Header>
          <Modal.Body
            style={{ border: "none", width: "70%", margin: "0 auto" }}
          >
            <div className="tittle__premium">
              <h1 className="highlighted">Sign up for this event</h1>
            </div>
            <p>
              Every field with an asterisk (
              <span style={{ color: "#FC3E39" }}>*</span>) is mandatory{" "}
            </p>
            <RegisterEventForm user={user} event={info.nid} />
          </Modal.Body>
        </Modal>
        <CommunityAd2 />
        <CommunityAdSingleVideo />
      </main>
    </Layout>
  );
};

export default EventPage;
