import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { articleService, userService } from "../_services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/contentsPage.css";
import logo from "../_images/icons/Vector.svg";
import { Course } from "../_components/lists/course";
import arrow_left from "../_images/ico-hfeatured-arrow-left.svg";
import arrow_right from "../_images/ico-hfeatured-arrow-right.svg";
import ico_back from "../_images/ico-back.svg";

const CoursePage = ({ id, me }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [courses, setCourses] = useState([]);
  const [coursesPage, setCoursesPage] = useState([]);
  const [page, setPage] = useState(0);
  const listCourse = useState([])[0];
  const [listCoursePage, setListCoursePage] = useState([]);
  let pageNum = page + 1;

  useEffect(() => {
    articleService
      .getInternalCourse(lang, id)
      .then((res) => res.json())
      .then((response) => {
        setCourses(response.rows[0]);
        listCourse(response.rows);
      })
      .catch((error) => console.log(error));

    return () => {
      setCourses({});
    };
  }, [id, lang]);

  useEffect(() => {
    articleService
      .getInternalCoursePage(lang, id, page)
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setCoursesPage(response.pager);
        setListCoursePage(response.rows);
      })
      .catch((error) => console.log(error));
  }, [page, id, lang]);

  useEffect(() => {
    /** Call Services  */
    const { id } = JSON.parse(localStorage.getItem("me"));
    let userId = String(id);

    userService
      .summary(lang, userId)
      .then((res) => res.json())
      .then((response) => {
        if (response[0].Roles !== "Premium") {
          setShow(true);
        }
      })
      .catch(console.log);
  }, [lang]);

  const handlePagePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handlePageNext = () => {
    if (pageNum < coursesPage.total_pages) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("contents.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Modal.Header style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          <div className="tittle__premium">
            <h1 className="highlighted text-center">
              <span>{t("premium.modal_title_1")}</span>
              {t("premium.modal_title_2")}
            </h1>
          </div>
          <p>{t("premium.modal_text")}</p>
          <Button
            variant="primary"
            href={`/${lang}/${t("url.premium")}`}
            className="modal__button"
          >
            {t("premium.modal_button")}
          </Button>
        </Modal.Body>
      </Modal>
      <section className="internal-course">
        <div className="internal-container-course">
          <button className="button-back-courses" onClick={() => handleBack()}>
            <img src={ico_back} alt="Back" />
            {t("buttons.back")}
          </button>
          <img
            className="image-course"
            src={courses.field_imagen_courses}
            atl="portada-image"
          />
          <div className="container-info">
            <div className="container-title">
              <h3 className="title-course">{courses.title}</h3>
            </div>
            <p className="description-course">{courses.field_description}</p>
            <div className="container-author">
              <img src={logo} alt="logo-author" />
              <p>{t("others.author-course")}</p>
              <p className="author-course">{courses.uid}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="list-course">
        <div className="courses__heading">
          <h3>{t("others.title-course")}</h3>
          <div className="navigation__courses d-flex align-items-center">
            <p className="text_content-course">
              {pageNum} / {coursesPage.total_pages}
            </p>
            <div className="buttons__courses d-flex align-items-center">
              <div className="nav__counter">
                <button onClick={() => handlePagePrev()}>
                  <img itemProp="image" src={arrow_left} alt="arrow_left" />
                </button>
                <button onClick={() => handlePageNext()}>
                  <img itemProp="image" src={arrow_right} alt="arrow_right" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul className="course-item">
          {listCoursePage.map((i, index) => (
            <li
              key={index}
              className="d-flex justify-content-center courses_list-item"
            >
              <Course i={i} nid={courses.nid} />
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export default CoursePage;
