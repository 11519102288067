import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { CategoryLink } from "./category-link";

export default function EventThumb({
  slug,
  img,
  title,
  location,
  startDate,
  category,
  description,
}) {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const formatSlug = (slug + "").substring(8);
  const link = `/${lang}/${t("url.events")}/${formatSlug}`;

  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="event"
    >
      <figure className="article__image__content event">
        <Link to={link}>
          <img itemProp="image" src={img} alt={title} />
        </Link>
      </figure>
      <div className="event__upcoming">
        <h2 itemProp="headline">
          <Link to={link}>{title}</Link>
        </h2>
        <div className="article__cat d-flex">
          <p className="article__cat__tag" rel="category">
            <CategoryLink name={category} />
          </p>
        </div>
        <p className="event_excerpt">{description}</p>
      </div>
    </article>
  );
}
