import React from "react";
import { appService } from "../../_services";
import { useState, useEffect } from "react";
import ico_delete from "../../_images/ico-delete.svg";
import ico_success from "../../_images/Vector.svg";
import { useTranslation } from "react-i18next";

export const Notification = ({ lang, uid }) => {
  const { t } = useTranslation("common");
  const [notification, setNotification] = useState([]);

  const onHandleClick = (e, not) => {
    appService.deleteNotificationUser(not.id).then((response) => {
      if (response.status === 204) {
        setNotification((notifications) =>
          notifications.filter((notification) => {
            return notification.id !== not.id;
          })
        );
      }
    });
    e.preventDefault();
  };

  const deleteAllNotification = (e, notifications) => {
    // OLD SERVICE
    // notifications.map((not) => {
    //   appService.deleteNotification(not.id).then((response) => {
    //     if (response.status === 204) {
    //       setNotification((notifications) =>
    //         notifications.filter((notification) => {
    //           return notification.id !== not.id;
    //         })
    //       );
    //     }
    //   });
    // });

    notifications.map((not) => {
      appService.deleteNotificationUser(not.id).then((response) => {
        if (response.status === 204) {
          setNotification((notifications) =>
            notifications.filter((notification) => {
              return notification.id !== not.id;
            })
          );
        }
      });
    });
    e.preventDefault();
  };

  useEffect(() => {
    // OLDSERVICE
    // appService.getNotification(lang, uid).then((response) => {
    //   response.text().then((text) => {
    //     const data = text && JSON.parse(text);
    //     setNotification(data);
    //     console.log(data);
    //   });
    // });

    appService.getNotificationUser(uid).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setNotification(data);
      })
    })
  }, [lang, uid, notification, setNotification]);

  const joinMe = (e, id, slug, not) => {
    appService
      .postJoinGroup({ id, slug })
      .then((res) => res.json())
      .then(() =>
        appService.deleteNotificationUser(not.id).then((response) => {
          if (response.status === 204) {
            setNotification((notifications) =>
              notifications.filter((notification) => {
                return notification.id !== not.id;
              })
            );
          }
        })
      )

      .catch(console.log);

    e.preventDefault();
  };

  return (
    <>
      <a
        className="allread"
        href="/"
        onClick={(e) => deleteAllNotification(e, notification)}
      >
        {t("profile.all_read")}
      </a>
      {notification.length > 0 && notification.map((not) => {
        const srcImg =
          not.field_use_avatar === "1"
            ? "/images/" + not.field_user_avatar + ".svg"
            : not.user_picture
            ? not.user_picture
            : "/images/no-avatar.png";
        return (
          <div
            className="notification__card d-flex justify-content-around"
            key={not.id}
          >
            <a
              href={
                not.field_type !== "like" && not.field_type !== "comment" && not.field_type !== "follow"
                  ? `/${lang}/${t("url.groups")}/${t("url.groups_discover")}`
                  : `/${lang}/${not.nid}`
              }
              className="read"
            >
              <div className="notification__card d-flex">
                <div className="notification__profile" title={t("user.notification_profile")}>
                  <img src={srcImg} alt="Profile" />
                </div>
                <div className="notification__detail">
                  <span className="not__content" title={t("user.not_content")}>
                    <strong>{not.field_name}, </strong>
                    {not.notification_text}
                  </span>
                  <span className="not__date" title={t("user.not_date")}>{not.created}</span>
                </div>
              </div>
              {/* {not.field_type !== "like" && not.field_type !== "comment" && not.field_type !== "follow" && (
                <div className="d-flex justify-content-start pl-5 ">
                  <div
                    title={t("user.accept")}
                    className="d-flex justify-content-center px-2 rounded border border-primary ml-2 align-items-center mr-3"
                  >
                    <p className="m-0 p-2">{t("user.accept")}</p>
                    <img
                      width="25"
                      height="25"
                      src={ico_success}
                      alt="Success"
                      style={{ marginRight: "15px", marginLeft: "15px" }}
                      onClick={(e) => joinMe(e, not.user_id, not.id_1, not)}
                      className="m-0"
                    />
                  </div>
                  <div
                    title={t("user.decline")}
                    className="d-flex justify-content-center px-2 rounded border border-primary align-items-center btn__decline"
                  >
                    <p className="m-0 p-2">{t("user.decline")}</p>
                    <img
                      width="25"
                      height="25"
                      src={ico_delete}
                      alt="Back"
                      onClick={(e) => onHandleClick(e, not)}
                      className="m-0"
                    />
                  </div>
                </div>
              )} */}
            </a>
            <a
              href="/"
              className="back__btn"
              onClick={(e) => onHandleClick(e, not)}
              title={t("user.back_btn")}
            >
              <img width="40" height="40" src={ico_delete} alt="Back" />
            </a>
          </div>
        );
      })}
    </>
  );
};
