import { CometChatUI } from "../Chat/CometChatWorkspace/src/components";
import React from "react";
import { CometChat } from "@cometchat-pro/chat";

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = process.env.REACT_APP_COMETCHAT_REGION;

if (appID) {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
  CometChat.init(appID, appSetting);
}

let authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;

function ChatRoom({ userInfo }) {
  const usuario = userInfo.name;

  setTimeout(function () {
    if (usuario) {
      CometChat.login(usuario, authKey);
    }
  }, 3000);

  return (
    <div style={{ height: "calc(100vh - 90px)" }}>
      <CometChatUI />
    </div>
  );
}

export default ChatRoom;
