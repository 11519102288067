import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";

export default function CommunityAd() {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [isFixed, setIsFixed] = useState(false);
  const [ads, setAds] = useState([]);
  const [showVideo, setShowVideo] = useState(true);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };

  useEffect(() => {
    const handleScroll = () => {
      const distanceFromTop = window.scrollY;
      const fixedPoint = 500; // Cambia este valor al punto de scroll deseado

      setIsFixed(distanceFromTop > fixedPoint);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    /** Call Services  */
    appService
      .getAds(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setAds(response);
        }
      })
      .catch(console.log);
  }, [lang, setAds]);

  return (
    <>
      {ads.length > 0 && ads[2]?.field_imagen && ads[3]?.field_videos && (
        <>
          <div
            className={` ads__container video-ads scroll-fixed-section  ${
              isFixed ? "fixed" : ""
            }`}
          >
            {showVideo && (
              <>
                <div className="close-button" onClick={toggleVideo}>
                  X
                </div>
                <ReactPlayer
                  url={ads[3].field_video || ads[3].field_videos}
                  controls={true}
                  width="100%"
                  height="auto"
                  playing={true}
                />
              </>
            )}
          </div>
          <div className="mt-5">
            <a
              href={ads[2].field_enlace_1}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ads[2].field_imagen} alt="Ad" />
            </a>
          </div>
        </>
      )}
    </>
  );
}
