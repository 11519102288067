import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { communityService, userService } from "../../_services";

const FollowButton = ({ me, uid, nick, following, className, id }) => {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [label, setLabel] = useState("follow");

  //Controls rendering
  useEffect(() => {
    setLabel("follow");
    if (following) {
      for (let i = 0; i < following.length; i++) {
        if (following[i].id == String(uid) || following[i].id == id) {
          setLabel("unfollow");
          break;
        }
      }
    }
  }, [uid, following, setLabel]);

  /* Follow user */
  const handlefollow = (e) => {
    if (following) {
      if (label === "follow") {
        following.push({
          id: uid,
          user: nick,
        });
        setLabel("unfollow");
        communityService.postNotificationUser("follow", uid, null, me.id);
        window.location.reload();
      } else {
        for (let i = 0; i < following.length; i++) {
          if (following[i].id == uid || following[i].id == id) {
            following.splice(i, 1);
            break;
          }
        }
        setLabel("follow");
        window.location.reload();
      }

      //Perfom service update.
      userService.follow(me.id, following).catch(console.log);
    }

    e.preventDefault();
  };

  if (!me) {
    return (
      <Link className={className} to={`/${lang}/${t("url.community")}`}>
        {t(`buttons.follow`)}
      </Link>
    );
  }

  return me.id !== uid ? (
    <a href="./" className={className} onClick={handlefollow}>
      {t(`buttons.${label}`)}
    </a>
  ) : (
    ""
  );
};

function mapState(state) {
  const { me } = state.authentication;
  const { following } = state.users;
  return { me, following };
}

const connectedFollowButton = connect(mapState)(FollowButton);
export { connectedFollowButton as FollowButton };
