import React, { useEffect, useState } from "react";

import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import FeaturedEvents from "../_components/lists/featured-events";
import UpcomingEvents from "../_components/lists/upcoming-events";
import RecentEvents from "../_components/lists/recent-events";
import EventBanner from "../_components/ui/event-banner";

import { eventService } from "../_services/event.service";
import e1 from "../_images/e1.png";
import e2 from "../_images/e2.png";
import e3 from "../_images/e3.png";
import e4 from "../_images/e4.png";

import * as moment from "moment";
import { path } from "../_helpers";
import CommunityAd2 from "../_components/ads/community-ad2";
import CommunityAdSingleVideo from "../_components/ads/community-ad-singleVideo";

const EventsPage = () => {
  const [data, setData] = useState([]);
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  useEffect(() => {
    /** Call Services  */
    eventService
      .getLastEvent(lang)
      .then((res) => res.json())
      .then((response) => {
        setData(response[0]);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  return (
    <Layout>
      <SEO lang={lang} title={t("events.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="event__head">
          <div className="banner__events d-flex flex-column">
            <div className="contains__banner__first d-flex justify-content-center container">
              <div className="text__banner d-flex justify-content-center align-items-center">
                <div className="title">
                  <span>Tech</span>
                  <h1>{t("events.title")}</h1>
                </div>
                <div className="body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Velit erat velit ornare aliquam, consect. Edit consectetum
                    alit ediam venut!{" "}
                  </p>
                </div>
              </div>
              <div className="images__banner">
                <img src={e1} alt="event1" className="img1" />
                <img src={e2} alt="event2" className="img2" />
                <img src={e3} alt="event3" className="img3" />
              </div>
            </div>

            <div className="contains__banner__two d-flex container align-items-end">
              <div className="contact">
                {/* Beginning of top trending section */}

                <EventBanner
                  slug={data.field_path}
                  key={data.field_name_evente + "banner"}
                  img={path(data.field_image)}
                  title={data.field_name_evente}
                  location={data.field_location}
                  startDate={moment(data.field_start_date).format(
                    "MMM DD, YYYY"
                  )}
                />
              </div>
              <div className="background">
                <img src={e4} alt="img4" />
              </div>
            </div>
          </div>
        </section>
        <CommunityAd2 />

        {/* Beginning feature section home */}
        <section className="featured__events no-bg">
          <FeaturedEvents />
        </section>

        {/* Upcomming events section */}
        <section className="featured__events recent_events">
          <div className="container">
            <UpcomingEvents />
          </div>
        </section>

        {/* Past events section */}
        <section className="featured__events recent_events">
          <div className="container">
            <RecentEvents />
          </div>
        </section>
        <CommunityAd2 />
        <CommunityAdSingleVideo />
      </main>
    </Layout>
  );
};

export default EventsPage;
