import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import icoLocation from "../../_images/ico-event-location.svg";
import icoDate from "../../_images/ico-event-date.svg";

export default function EventBanner({ slug, img, title, startDate, location }) {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  // Verificar si slug tiene un valor antes de usarlo
  const formatSlug = slug && slug.substring(8);

  const link = formatSlug ? `/${lang}/${t("url.events")}/${formatSlug}` : "";

  return (
    <section className="featured__event">
      <div className="container d-flex flex-column">
        <div className="description__featured">
          <p>{t("events.event_nearest")}</p>
          <h1>{title}</h1>
          <div className="event__date">
            <span className="event__location">{location}</span>
            <span className="event__date">{startDate}</span>
          </div>
          <div className="event__cta">
            {link && <Link to={link}>{t("events.details")}</Link>}
            <a href="https://www.allticketscol.com/" target="_blank">
              {t("events.buy_tickets")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
