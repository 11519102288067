import React, { useEffect, useState } from "react";
import { PostOwned } from "../ui/post-owned";
import { PostFeed } from "../ui/post-feed";
import { connect } from "react-redux";
import { PostQuote } from "../ui/post-quote";
import { PostSomething } from "../ui/post-something";
import { Spinner } from "../ui/spinner";
import { LoadMore } from "../contents/load-more";
import { communityConstants } from "../../_constants";
import { communityService } from "../../_services";
import { useTranslation } from "react-i18next";
import { PostSomethingGroup } from "../ui/post-something-group";

const CommunityPostsGroup = ({ section, user, group, idGroup, members }) => {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  //Execute service call
  // eslint-disable-next-line
  const fetchResults = (lang) => {
    /** Call Services  */
    setLoading(true);
    communityService
      .getWallContentGroup(lang, idGroup)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setTotal(response.results);
          setPosts(posts.concat(response.articles));
        }
        setLoading(false);
      })
      .catch(console.log);
  };

  //Gather articles.
  useEffect(() => {
    fetchResults(lang, page);
    resultFeed();
  }, [lang, page]);

  const handleMore = () => {
    setPage(page + 1);
    fetchResults(lang, page + 1);
  };

  const onAddedMessage = (post) => {
    setTotal(total + 1);
    setPosts([post, ...posts]);
  };

  const onDeletePost = (post) => {
    setPosts(posts.filter((item) => item.id !== post.id));
  };

  const resultFeed = () => {
    members.filter((i) => console.log(i.uid));
  };

  return (
    <>
      {members.map((i) => {
        const { id } = JSON.parse(localStorage.getItem("me"));
        if (i.uid == id) {
          return <PostSomethingGroup onMessage={onAddedMessage} group={group} key={i.uid} />;
        }
      })}

      <div className="feeds__community mb-6">
        {
          /* Render wall posts */
          posts.map((item, i) => {
            if (item.type === "Post") {
              return <PostQuote post={item} key={item.id} user={user} />;
            } else if (
              item.type === "Article" &&
              item.user === user.current_user.name
            ) {
              return (
                <PostOwned post={item} key={item.id} onDelete={onDeletePost} />
              );
            } else if (item.type === "Article") {
              return <PostFeed post={item} key={item.id} />;
            }

            return null;
          })
        }
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      {
        /* Load More button */
        !loading ? (
          <LoadMore
            page={page}
            totalItems={total}
            itemsByPage={communityConstants.WALLPOSTS_BY_PAGE}
            onClick={handleMore}
          />
        ) : (
          <Spinner />
        )
      }
    </>
  );
};

function mapState(state) {
  const { user } = state.authentication;
  return { user };
}

const connectedCommunityPostsGroup = connect(
  mapState,
  null
)(CommunityPostsGroup);
export { connectedCommunityPostsGroup as CommunityPostsGroup };
