import React from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { modalActions, userActions } from "../../_actions";
import { Avatar } from "../users/avatar";

import { CometChat } from "@cometchat-pro/chat";

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = process.env.REACT_APP_COMETCHAT_REGION;

if (appID) {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
  CometChat.init(appID, appSetting);
}

let authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;

class LoggedProfile extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.props.login();
  };

  handleRegister = (e) => {
    e.preventDefault();
    this.props.register();
  };

  handleLogout = (e) => {
    e.preventDefault();
    this.props.logout();
  };

  render() {
    const { t, i18n, user, me } = this.props;
    const lang = i18n.language;

    if (me) {
      CometChat.login(me.name, authKey);
    }

    return (
      <>
        {!user || !me ? (
          <>
            <a
              href="./"
              className="btn btn-secondary login__header desktop"
              data-toggle="modal"
              data-target="#login"
              onClick={this.handleLogin}
            >
              {t("user.login_button")}
            </a>
            <a
              href="./"
              className="btn btn-primary sign__header"
              data-toggle="modal"
              data-target="#signup"
              onClick={this.handleRegister}
            >
              {t("user.signin_button")}
            </a>
          </>
        ) : (
          <div className="profile__btn__content dropdown">
            <Link
              className="profile__btn"
              to={`/${lang}/${t("home.profile")}/${String(
                me.id
              ).toLocaleLowerCase()}`}
              onClick={() =>
                setTimeout(() => {
                  window.location.reload();
                }, 500)
              }
            >
              {me.notifications && (
                <span className="notification__counter">
                  {me.notifications}
                </span>
              )}
              <Avatar user={me} alt={t("user.your_profile")} />
            </Link>
            <ul className="lista__user dropdown-list">
              <div className="user__info__container">
                <Link
                  to={`/${lang}/${t("url.profile")}/${me.id}`}
                  className="no-border"
                  onClick={() =>
                    setTimeout(() => {
                      window.location.reload();
                    }, 500)
                  }
                >
                  <div className="user__image">
                    <Avatar user={me} alt={t("user.your_profile")} />
                  </div>
                </Link>
                <div className="user__details">
                  <span className="user__name">{user.current_user.name}</span>
                  <span className="user__profile">
                    @{user.current_user.name}
                  </span>
                  <Link to={`/${lang}/${t("url.profile")}/${t("url.edit")}`}>
                    {t("user.edit_profile")}
                  </Link>
                </div>
              </div>
              <li>
                <Link
                  to={`/${lang}/${t("url.profile")}/${t("url.notifications")}`}
                >
                  {t("user.notification_button")}
                  {me.notifications && (
                    <span className="notification__counter">
                      {me.notifications}
                    </span>
                  )}
                </Link>
              </li>
              <li>
                <Link to={`/${lang}/${t("url.profile")}`}>
                  {t("user.profile_button")}
                </Link>
              </li>
              <li>
                <a href={`/${lang}/${t("url.chat")}`}>Chat</a>
              </li>
              <li>
                <a
                  href="./"
                  className="logout__btn"
                  onClick={this.handleLogout}
                >
                  {t("user.logout_button")}
                </a>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  register: modalActions.showRegister,
  login: modalActions.showLogin,
  logout: userActions.logout,
};

const connectedLoggedProfile = connect(
  mapState,
  actionCreators
)(withTranslation("common")(LoggedProfile));
export { connectedLoggedProfile as LoggedProfile };
