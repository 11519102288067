import React, { useState } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Avatar } from "../users/avatar";
import { communityService } from "../../_services";
import { Spinner } from "./spinner";
import { modalActions } from "../../_actions";
import * as moment from "moment";

const PostSomethingGroup = ({ user, me, onMessage, showAddArticle, group }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [something, setSomething] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleShowVideo = (e) => {
    e.preventDefault();
    showAddArticle("video");
  };

  const handleShowArticle = (e) => {
    e.preventDefault();
    showAddArticle("article");
    if (group === "true") {
      localStorage.setItem("groupArticle", JSON.stringify(true));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSubmit(value.length > 0);
    setSomething(value);
  };

  const handleShareUpdate = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const d = new Date();
    const obj = {
      id: 0,
      title: something,
      display_name: me.display_name,
      user: me.name,
      role: me.role,
      type: "Post",
      date: moment(d).format("YYYY-MM-DD HH:mm:ss"),
      comments: "0",
      views: "0",
      votes: "0",
      likes: "0",
    };

    setLoading(true);
    setError("");
    communityService
      .addPost(lang, obj)
      .then((response) => {
        //Reset form.
        obj.id = response.nid[0].value;
        setSubmit(false);
        setSomething("");
        setLoading(false);
        onMessage(obj);
      })
      .then(() => {
        const { id } = JSON.parse(localStorage.getItem("me"));
        const group_id = JSON.parse(localStorage.getItem("group_id"));
        const post_id = obj.id;

        communityService.postGroup(id, group_id, post_id);
      }).then(() => {
        window.location.reload();
      })
      .catch((msg) => {
        setError(msg);
        setLoading(false);
      });
  };

  return (
    <div className="profile__header community">
      <div className="tabs__container create__content">
        <ul
          className="nav nav-tabs draggable draggable-center ui-draggable ui-draggable-handle"
          role="tablist"
          style={{ position: "relative", width: "470.923px" }}
        >
          <li role="presentation" className="active">
            <a
              href="/"
              aria-controls="update"
              role="tab"
              data-toggle="tab"
              onClick={handleShareUpdate}
            >
              {t("community.share_update")}
            </a>
          </li>
          <li role="presentation">
            <a
              style={{ paddingLeft: "40px" }}
              className="video__c"
              href="#profile"
              aria-controls="video"
              role="tab"
              data-toggle="tab"
              onClick={handleShowVideo}
            >
              {t("community.share_video")}
            </a>
          </li>
          <li role="presentation" className="pl-3">
            <a
              style={{ paddingLeft: "35px" }}
              className="article__c"
              href="#messages"
              aria-controls="article"
              role="tab"
              data-toggle="tab"
              onClick={handleShowArticle}
            >
              {t("community.write_article")}
            </a>
          </li>
        </ul>
      </div>

      <div className="update__textinput d-flex align-items-top">
        <div className="profile__btn__content dropdown">
          <Link
            to={`/${lang}/${t("home.profile")}/${String(
              me.id
            ).toLocaleLowerCase()}`}
            className="profile__btn user"
          >
            <Avatar user={me} alt={user.current_user.display_name} />
          </Link>
        </div>
        <div className="text__update">
          <form method="post" onSubmit={handleSubmit}>
            {error && (
              <div className="form-label-group">
                <div className="alert alert-danger text-center">
                  {t(`errors.${error}`)}
                </div>
              </div>
            )}

            <div className="form-label-group">
              <textarea
                id="something"
                name="something"
                placeholder={t("community.write_something")}
                value={something}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="form-label-group m-0 text-right">
              {submit && !loading && (
                <button type="submit" className="btn btn-secondary px-4 py-1">
                  {t("createArticle.button")}
                </button>
              )}

              {loading && <Spinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  showAddArticle: modalActions.showAddArticle,
};

const connectedPostSomethingGroup = connect(
  mapState,
  actionCreators
)(PostSomethingGroup);
export { connectedPostSomethingGroup as PostSomethingGroup };
