import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { Spinner } from "../ui/spinner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Profession } from "../ui/profession";
import { userService, saveFile } from "../../_services";

class ProfileForm extends FormValidator {
  constructor(props) {
    super(props);
    this.state = {
      elements: {
        name: {
          value: props.me.display_name,
          error: false,
          message: "",
        },
        role: {
          value: props.me.field__professions_1 ?? null,
          label: props.me.field__professions ?? null,
          error: false,
          message: "",
        },
        image: {
          base64: props.me.picture,
          name: "",
          type: "",
        },
        avatar: {
          use: props.me.use_avatar === "True" ? true : false,
          avatar: props.me.avatar,
        },
      },
      loading: false,
      problem: null,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.changeSelectHandler = this.changeSelectHandler.bind(this);
  }

  /* Form submit */
  submitHandler(e) {
    e.preventDefault();

    const isValid = this.validate(this.state.elements, [
      {
        field: "name",
        type: "required",
        message: "errors.name_required",
      },
      {
        field: "role",
        type: "required",
        message: "errors.role_required",
      },
    ]);

    if (isValid) {
      const { me } = this.props;
      const { name, role, image, avatar } = this.state.elements;
      const lang = this.props.i18n.language;
      //submit data.
      this.setState({ loading: true });
      if (image.name) {
        saveFile(image.base64, image.name, image.type, name.value, me.id)
          .then((fid) => {
            userService
              .updateProfile(lang, me, {
                field_name: {
                  value: name.value,
                },
                field__professions: [
                  {
                    target_id: role.value,
                  },
                ],
                user_picture: [
                  {
                    target_id: fid,
                  },
                ],
                field_user_avatar: {
                  value: avatar.avatar,
                },
                field_use_avatar: {
                  value: avatar.use,
                },
              })
              .then((newMe) => {
                //Override properties
                me.display_name = newMe.me.display_name;
                me.field__professions = newMe.me.field__professions;
                me.field__professions_1 = newMe.me.field__professions_1;
                me.picture = newMe.me.picture;
                me.avatar = newMe.me.avatar;
                me.use_avatar = newMe.me.use_avatar;
                //Show popup
                this.props.onSuccess(true);
              })

              .catch((msg) => {
                this.setState({ problem: msg, loading: false });
              });
          })
      } else {
        userService
          .updateProfile(lang, me, {
            field_name: {
              value: name.value,
            },
            field__professions: [
              {
                target_id: role.value,
              },
            ],
            field_user_avatar: {
              value: avatar.avatar,
            },
            field_use_avatar: {
              value: avatar.use,
            },
          })
          .then((newMe) => {
            //Override properties
            me.display_name = newMe.me.display_name;
            me.field__professions = newMe.me.field__professions;
            me.field__professions_1 = newMe.me.field__professions_1;
            me.avatar = newMe.me.avatar;
            me.use_avatar = newMe.me.use_avatar;
            //Show popup
            this.props.onSuccess(true);
          })

          .catch((msg) => {
            this.setState({ problem: msg, loading: false });
          });
      }
    }
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      const img = this.state.elements["image"];
      img.base64 = event.target.result;
      img.name = files[0].name;
      img.type = files[0].type;
      this.setState({ elements: this.state.elements });
    };
  }

  /* Controlled input */
  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }
  changeSelectHandler(e) {
    const input = this.state.elements["role"];
    input.value = e.value;
    input.label = e.label;
    input.error = false;
    this.setState({ elements: this.state.elements });
  }
  onRadioChange(e) {
    const input = this.state.elements["avatar"];
    input.avatar = e.target.value;
    this.setState({ elements: this.state.elements });
  }
  handleCheckChange(e) {
    const input = this.state.elements["avatar"];
    input.use = e.target.checked;
    this.setState({ elements: this.state.elements });
  }

  /* View */
  render() {
    const { t } = this.props;
    const { name, role, avatar } = this.state.elements;

    const avatars = [
      {
        id: "avatar-01",
        uri: "avatar-01.svg",
      },
      {
        id: "avatar-02",
        uri: "avatar-02.svg",
      },
      {
        id: "avatar-03",
        uri: "avatar-03.svg",
      },
      {
        id: "avatar-04",
        uri: "avatar-04.svg",
      },
      {
        id: "avatar-05",
        uri: "avatar-05.svg",
      },
      {
        id: "avatar-06",
        uri: "avatar-06.svg",
      },
      {
        id: "avatar-07",
        uri: "avatar-07.svg",
      },
      {
        id: "avatar-08",
        uri: "avatar-08.svg",
      },
      {
        id: "avatar-09",
        uri: "avatar-09.svg",
      },
      {
        id: "avatar-10",
        uri: "avatar-10.svg",
      },
      {
        id: "avatar-11",
        uri: "avatar-11.svg",
      },
      {
        id: "avatar-12",
        uri: "avatar-12.svg",
      },
      {
        id: "avatar-13",
        uri: "avatar-13.svg",
      },
      {
        id: "avatar-14",
        uri: "avatar-14.svg",
      },
      {
        id: "avatar-15",
        uri: "avatar-15.svg",
      },
      {
        id: "avatar-16",
        uri: "avatar-16.svg",
      },
      {
        id: "avatar-17",
        uri: "avatar-17.svg",
      },
      {
        id: "avatar-18",
        uri: "avatar-18.svg",
      },
      {
        id: "avatar-19",
        uri: "avatar-19.svg",
      },
      {
        id: "avatar-20",
        uri: "avatar-20.svg",
      },
      {
        id: "avatar-21",
        uri: "avatar-21.svg",
      },
      {
        id: "avatar-22",
        uri: "avatar-22.svg",
      },
      {
        id: "avatar-23",
        uri: "avatar-23.svg",
      },
      {
        id: "avatar-24",
        uri: "avatar-24.svg",
      },
      {
        id: "avatar-25",
        uri: "avatar-25.svg",
      },
      {
        id: "avatar-26",
        uri: "avatar-26.svg",
      },
      {
        id: "avatar-27",
        uri: "avatar-27.svg",
      },
      {
        id: "avatar-28",
        uri: "avatar-28.svg",
      },
      {
        id: "avatar-29",
        uri: "avatar-29.svg",
      },
    ];
    const listItems = avatars.map(({ id, uri }) => {
      return (
        <label key={id}>
          <input
            type="radio"
            value={id}
            name="avatar"
            checked={avatar.avatar === id}
            onChange={this.onRadioChange}
          />
          <img src={`/images/${uri}`}></img>
        </label>
      );
    });
    return (
      <form
        onSubmit={this.submitHandler}
        className=" certification__box  edit-img-profile"
      >
        {
          /* Error */
          this.state.problem && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t(`errors.${this.state.problem}`)}
              </div>
            </div>
          )
        }
        <h3>
          <span>{t("profile.edit_about-blue")}</span>
          {t("profile.edit_profile")}
        </h3>
        <label id="useAvatar">
          Use avatar:
          <input
            name="useAvatar"
            type="checkbox"
            checked={avatar.use}
            onChange={this.handleCheckChange}
          />
        </label>
        {listItems}
        <input
          type="file"
          className="form-control"
          name="image"
          onChange={this.onFileChange}
        />
        <img src={this.state.elements.image.base64} className="img-file" />
        <div className="formedit__camps d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
          <div
            className={`form-label-group col-12 col-sm-12 col-md-6 col-lg-6 ${
              name.error ? "error" : ""
            }`}
          >
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Your name"
              required=""
              autoFocus=""
              defaultValue={name.value}
              onChange={this.changeHandler}
            />
            <label htmlFor="name">{t("profile.your_name")}</label>
            {name.error && (
              <small className="form-text text-muted">{t(name.message)}</small>
            )}
          </div>
          <div
            className={`form-label-group col-12 col-sm-12 col-md-6 col-lg-6 ${
              role.error ? "error" : ""
            }`}
          >
            <Profession
              value={role.value}
              label={role.label}
              handler={this.changeSelectHandler}
            />
            {/* <label htmlFor="role">{t("profile.yout_profession")}</label> */}
            {role.error && (
              <small className="form-text text-muted">{t(role.message)}</small>
            )}
          </div>
        </div>
        {/* Submit */}
        {this.state.loading ? (
          <Spinner />
        ) : (
          <button className="btn-primary" type="submit">
            {t("profile.save")}
          </button>
        )}
      </form>
    );
  }
}

function mapState(state) {
  const { me } = state.authentication;
  return { me };
}

const connectedProfileForm = connect(
  mapState,
  null
)(withTranslation("common")(ProfileForm));
export { connectedProfileForm as ProfileForm };
