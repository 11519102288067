import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import he from "he";
import Select from "react-select";

const CategorySelect = ({ config, handler, value }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  let categoryList = [];

  if (config) {
    //If any category was found.
    config.categories[lang].map((item, id) =>
      categoryList.push({
        value: he.decode(item.category),
        label: he.decode(item.category),
      })
    );

    return (
      <>
        <Select
          options={categoryList}
          onChange={handler}
          defaultValue={{ label: value }}
        />
      </>
    );
  }

  return "";
};

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedCategorySelect = connect(mapState)(CategorySelect);
export { connectedCategorySelect as CategorySelect };
