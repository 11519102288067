import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import RegisterEventForm from "../forms/register-event-form";

export default function EventBannerDetail({
  img,
  location,
  startDate,
  endDate,
  email,
  buttonBuy,
  name,
  category,
  user,
  event,
  existMember,
}) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row">
      <div
        style={{
          backgroundImage: `url("${img}")`,
        }}
        className="featured__image col-12 col-sm-12 col-md-8 col-lg-7"
      ></div>
      <div className="description__featured col-12 col-sm-12 col-md-4 col-lg-5">
        <span className="main__title__event">{name}</span>
        <div className="event__details">
          <div className="event__category">
            <span>{category}</span>
          </div>
          <div className="location__event">
            <span className="event__location">{location}</span>
          </div>
          <div className="event__start">
            <span className="event__date">{startDate}</span>
          </div>
          <div className="events__tickets">
            {/* {ReactHtmlParser(buttonBuy)} */}
            {existMember ? (
              <p>{t("events.registered")}</p>
            ) : (
              <button onClick={() => setShow(true)}>
                {t("register.title")}
              </button>
            )}

            <a href="https://www.allticketscol.com/" target="_blank">
              {t("events.buy_tickets")}
            </a>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
          style={{ border: "none", width: "70%", margin: "0 auto" }}
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ border: "none", width: "70%", margin: "0 auto" }}>
          <div className="tittle__premium">
            <h1 className="highlighted">Sign up for this event</h1>
          </div>
          <p>
            Every field with an asterisk (
            <span style={{ color: "#FC3E39" }}>*</span>) is mandatory{" "}
          </p>
          <RegisterEventForm user={user} event={event} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
