import React from "react";
import Modal from "react-bootstrap/Modal";
import ico_back from "../../_images/ico-back.svg";
import {
  createCertification,
  updateCertification,
  deleteCertification,
} from "../../_services";
import FormValidator from "../../_helpers/form-validator";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class CertificationForm extends FormValidator {
  constructor(props) {
    super(props);
    const { id, url_image, name } = props.data;

    this.state = {
      elements: {
        id: id,
        image: {
          base64: url_image,
          name: "",
          type: "",
        },
        name: {
          value: name,
          error: false,
          message: "",
        },
      },
      loading: false,
      problem: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onFileChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      const img = this.state.elements["image"];
      img.base64 = event.target.result;
      img.name = files[0].name;
      img.type = files[0].type;
      this.setState({ elements: this.state.elements });
    };
  };
  handleInputChange = (e) => {
    const img = this.state.elements["name"];
    img.value = e.target.value;
    this.setState({ elements: this.state.elements });
  };
  handleSubmit = (e) => {
    const { me } = this.props;
    const { id, name, image } = this.state.elements;
    const lang = this.props.i18n.language;
    if (id === 0) {
      createCertification(me, name.value, image, lang)
        .then((newMe) => {
          this.props.certification(newMe.me.custom_user_certification);
          me.custom_user_certification = newMe.me.custom_user_certification;
        })
        .then(() => {
          this.props.onHide(true);
        });
    } else {
      updateCertification(id, me, name.value, image, lang)
        .then((newMe) => {
          this.props.certification(newMe.custom_user_certification);
          me.custom_user_certification = newMe.custom_user_certification;
        })
        .then(() => {
          this.props.onHide(true);
        });
    }
    // this.props.onHide(true)
    e.preventDefault();
  };

  deleteSubmit = (e) => {
    const { me } = this.props;
    const { id } = this.state.elements;
    const lang = this.props.i18n.language;
    deleteCertification(id, me, lang).then((newMe) => {
      this.props.certification(newMe.custom_user_certification);
      me.custom_user_certification = newMe.custom_user_certification;
    });
    this.props.onHide(true);
    e.preventDefault();
  };

  /* View */
  render() {
    const { t } = this.props;
    const imgRequired = this.state.elements.image.base64 ? false : true;
    const { id } = this.state.elements;
    const button_delete =
      id !== 0 ? (
        <>
          <button
            type="submit"
            className="btn btn-outline-danger"
            onClick={this.deleteSubmit}
          >
            {t("profile.delete")}
          </button>
        </>
      ) : (
        <></>
      );

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        className="modal-add-certification"
      >
        <div
          id={this.state.elements.id}
          tabIndex="0"
          role="dialog"
          aria-labelledby="modalLabel_2"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered edit__profile"
            role="document"
          >
            <div className="modal-content auto">
              <button
                type="button"
                className="close"
                id="close_add_edit_certifications"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.onHide}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="mobile__bar d-flex align-items-center">
                <a href="/" className="back__btn">
                  <img src={ico_back} alt="Back" />
                </a>
                <span className="title__profile"></span>
              </div>
              <div className="profile__camps certifications">
                <form className=" certification__box">
                  <input
                    type="text"
                    placeholder="Nombre de la certificación..."
                    value={this.state.elements.name.value}
                    onChange={this.handleInputChange}
                    required
                  />
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={this.onFileChange}
                    required={imgRequired}
                  />
                  <img
                    src={this.state.elements.image.base64}
                    className="img-file"
                    alt="certification"
                  />
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    {t("profile.save")}
                  </button>
                  {button_delete}
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  const { me } = state.authentication;
  return { me };
}

const connectedCertication = connect(
  mapState,
  null
)(withTranslation("common")(CertificationForm));
export { connectedCertication as CertificationForm };
