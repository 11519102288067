import React from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { AccountForm } from "../_components/forms";
import { ProfileMenu } from "../_components/navigation/profile-menu";
import { Spinner } from "../_components/ui/spinner";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import PasswordForm from "../_components/forms/password-form";
import Credits from "../_components/common/credits";

import logoPremium from "../_images/modal-premium/corona.png";
import CommunityAd2 from "../_components/ads/community-ad2";
import CommunityAdSingleVideo from "../_components/ads/community-ad-singleVideo";

const ProfileAccount = ({ me, user }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("profile.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      ></main>
      <section className="profile__page">
        <div className="container d-flex">
          {/* Sidebar menu */}
          <ProfileMenu selected="account" />

          {/* Main content */}
          <div className="profile__body col-lg-10 col-md-9">
            {!me ? (
              <Spinner />
            ) : (
              <div className="profile__header my__account">
                <div className="trends__title">
                  <h2 className="new__content">{t("menus.my_account")}</h2>
                  <p>{t("profile.desc-account")}</p>
                </div>
                {/* User form data */}
                <div className="profile__form">
                  <AccountForm user={me} />
                </div>

                {/* User password */}
                <PasswordForm
                  email={me.custom_user_email}
                  token={user.csrf_token}
                  name={me.name}
                />

                {/* User suscription status */}
                <div className="suscription__form">
                  <h4 className="account__title"> {t("forms.subscription")}</h4>
                  {me?.Roles !== "Premium" ? (
                    <span>{t("forms.subscription-message")}</span>
                  ) : (
                    <>
                      <img
                        src={logoPremium}
                        alt="logo"
                        style={{ width: "40px", margin: "0 5px" }}
                      />
                      <span>{t("forms.isPremium")}</span>
                    </>
                  )}
                </div>
              </div>
            )}
            <CommunityAd2 />
            <CommunityAdSingleVideo />
            <Credits />
          </div>
        </div>
      </section>
      <MobileMenu selected="profile" withFade={true} />
    </Layout>
  );
};

function mapState(state) {
  const { me, user } = state.authentication;
  return { me, user };
}

const connectedProfileAccount = connect(mapState, null)(ProfileAccount);
export { connectedProfileAccount as ProfileAccount };
