import React from "react";
import FormValidator from "../../_helpers/form-validator";
import { meetupService } from "../../_services";
import { withTranslation } from "react-i18next";
import { Link } from "@reach/router";
import ico_back_large from "../../_images/ico-back-large.svg";

class MeetupForm extends FormValidator {
  constructor(props) {
    super(props);
    this.state = {
      elements: {
        where: {
          value: "",
          error: false,
          message: "",
        },
        when: {
          value: "",
          error: false,
          message: "",
        },
        description: {
          value: "",
          error: false,
          message: "",
        },
        url: {
          value: "",
          error: false,
          message: "",
        },
      },
      loading: false,
      error: "",
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  submitHandler(e) {
    e.preventDefault();

    const { language: lang } = this.props.i18n;
    const isValid = this.validate(this.state.elements, [
      {
        field: "where",
        type: "required",
        message: "errors.where_required",
      },
      {
        field: "when",
        type: "required",
        message: "errors.when_required",
      },
      {
        field: "when",
        type: "future",
        message: "errors.when_future",
      },
      {
        field: "description",
        type: "required",
        message: "errors.description_required",
      },
      {
        field: "url",
        type: "url",
        message: "errors.wrong_url",
      },
      {
        field: "url",
        type: "required",
        message: "errors.wrong_required",
      },
    ]);
    this.setState({ error: "" });

    if (isValid) {
      const { where, when, description, url } = this.state.elements;
      this.setState({ loading: true });

      meetupService
        .postMeetups(
          lang,
          description.value,
          url.value,
          when.value + "T08:00:00-00:00",
          where.value
        )
        .then(() => {
          //Show popup
          this.props.onSuccess(true);

          //Reset form.
          const elements = this.state.elements;
          elements.where.value = "";
          elements.when.value = "";
          elements.description.value = "";
          elements.url.value = "";
          this.setState({
            elements: elements,
          });
        })
        .catch((msg) => {
          this.setState({ error: msg });
        });
    }
  }

  changeHandler(e) {
    const input = this.state.elements[e.target.name];
    input.value = e.target.value;
    input.error = false;

    this.setState({ elements: this.state.elements });
  }

  render() {
    const { t, i18n } = this.props;
    const lang = i18n.language;
    const { where, when, description, url } = this.state.elements;

    return (
      <>
        {
          /* Error */
          this.state.error && (
            <div className={`form-label-group`}>
              <div className="alert alert-danger text-center">
                {t("errors.meetup_api")}
              </div>
            </div>
          )
        }

        <form className="form-signin" onSubmit={this.submitHandler}>
          <div className={`form__nofloating ${where.error ? "error" : ""}`}>
            <label htmlFor="where">
              {t("meetups.lbl_where")} *
              <input
                type="text"
                id="where"
                name="where"
                className="form-control"
                value={where.value}
                placeholder={t("meetups.search_city")}
                onChange={this.changeHandler}
                maxLength="50"
              />
              {where.error && (
                <small className="form-text text-muted">
                  {t(where.message)}
                </small>
              )}
            </label>
          </div>
          <div className={`form__nofloating ${when.error ? "error" : ""}`}>
            <label htmlFor="when">
              {t("meetups.lbl_when")} *
              <input
                type="date"
                id="when"
                className="form-control"
                name="when"
                value={when.value}
                onChange={this.changeHandler}
                maxLength="10"
              />
              {when.error && (
                <small className="form-text text-muted">
                  {t(when.message)}
                </small>
              )}
            </label>
          </div>
          <div
            className={`form__nofloating ${description.error ? "error" : ""}`}
          >
            <label htmlFor="description">
              {t("meetups.lbl_description")} *
              <input
                type="text"
                id="description"
                name="description"
                className="form-control"
                placeholder={t("meetups.txt_description")}
                value={description.value}
                onChange={this.changeHandler}
                maxLength="80"
              />
              {description.error && (
                <small className="form-text text-muted">
                  {t(description.message)}
                </small>
              )}
            </label>
          </div>
          <div
            className={`form__nofloating last__meetup ${
              url.error ? "error" : ""
            }`}
          >
            <label htmlFor="url">
              {t("meetups.lbl_url")} *
              <input
                type="text"
                id="url"
                name="url"
                className="form-control"
                placeholder={t("meetups.txt_link")}
                value={url.value}
                onChange={this.changeHandler}
                maxLength="120"
              />
              {url.error && (
                <small className="form-text text-muted">{t(url.message)}</small>
              )}
              <small id="urlHelp" className="form-text text-muted">
                {t("meetups.txt_link_help")}
              </small>
            </label>
          </div>
          <div className="clearfix"></div>
          <div className="buttons">
            <button type="submit" className="btn btn-primary">
              {t("meetups.cta_post")}
            </button>
            <Link to={`/${lang}/${t("url.meetups")}`} className="btn btn-back">
              <img src={ico_back_large} alt="Back to list" />
              {t("buttons.back_list")}
            </Link>
          </div>
        </form>
      </>
    );
  }
}

export default withTranslation("common")(MeetupForm);
