import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";
import { Linkedin, Facebook, Youtube, Instagram, Twitter } from "../buttons";

export const SocialsMenu = ({ className }) => {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [networks, setNetworks] = useState(null);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    appService
      .getMenu(lang, "social-networks")
      .then((res) => res.json())
      .then((response) => {
        if (isMounted) {
          setNetworks(response.data["#items"]);
        }
      })
      .catch(console.log);

    return () => {
      setIsMounted(false);
    };
  }, [lang, isMounted]);

  //Loading
  if (!networks) {
    return "";
  }

  //Render
  return (
    <div className={`social__follow row ${className}`}>
      <span>{t("footer.followus")}</span>
      <div className="social__container">
        {Object.keys(networks).map((i) => (
          <React.Fragment key={i}>
            {String(networks[i].title).toLocaleLowerCase() === "linkedin" && (
              <Linkedin link={networks[i].url} />
            )}
            {String(networks[i].title).toLocaleLowerCase() === "facebook" && (
              <Facebook link={networks[i].url} />
            )}
            {String(networks[i].title).toLocaleLowerCase() === "youtube" && (
              <Youtube link={networks[i].url} />
            )}
            {String(networks[i].title).toLocaleLowerCase() === "instagram" && (
              <Instagram link={networks[i].url} />
            )}
            {String(networks[i].title).toLocaleLowerCase() === "twitter" && (
              <Twitter link={networks[i].url} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
