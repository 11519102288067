import React, { useEffect, useState, useRef } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { Stream } from "@cloudflare/stream-react";

import ico_back from "../_images/ico-back.svg";
import "../_styles/style.css";
import { appService } from "../_services";

const InternalCoursePage = (info) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [showSkipButton, setShowSkipButton] = useState(false);
  const overlayVideoRef = useRef(null); // Referencia al elemento de video
  const skipButtonRef = useRef(null); // Referencia al botón "Skip"
  const [ads, setAds] = useState([]);

  const { location } = info;

  const handleBack = () => {
    window.history.back();
  };

  if (location.state === null) {
    window.location.href = `/${lang}`;
  }

  useEffect(() => {
    // Mostrar el botón "Skip" después de 5 segundos
    const skipButtonTimeout = setTimeout(() => {
      setShowSkipButton(true);
    }, 5000);

    // Limpieza del timeout cuando el componente se desmonta
    return () => clearTimeout(skipButtonTimeout);
  }, []);

  useEffect(() => {
    /** Call Services  */
    appService
      .getAds(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setAds(response);
        }
      })
      .catch(console.log);
  }, [lang, setAds]);

  const handleSkip = () => {
    if (overlayVideoRef.current) {
      overlayVideoRef.current.pause();
    }

    // Oculta el botón de "Saltar anuncio"
    if (skipButtonRef.current) {
      skipButtonRef.current.style.display = "none";
    }
    if (overlayVideoRef.current) {
      overlayVideoRef.current.style.display = "none";
    }
  };

  const handleVideoEnded = () => {
    // Oculta el botón "Saltar" y el video cuando el video principal termina
    if (skipButtonRef.current) {
      skipButtonRef.current.style.display = "none";
    }
    if (overlayVideoRef.current) {
      overlayVideoRef.current.style.display = "none";
    }
  };

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("menus.community")} />

      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section>
          <div className="container">
            <div className="detail__header d-flex flex-column">
              <div className="detail__header__container">
                <div className="detail__container">
                  <button className="button-back" onClick={() => handleBack()}>
                    <img src={ico_back} alt="Back" />
                    {t("buttons.back")}
                  </button>
                  <h2>{location.state.info.i.title}</h2>
                </div>
              </div>

              <div className="detail__header__container__a video-container">
                {ads.length > 0 && ads[3]?.field_videos && (
                  <video
                    ref={overlayVideoRef}
                    autoPlay
                    id="overlay-video"
                    onEnded={handleVideoEnded}
                  >
                    <source
                      src={ads[3].field_video || ads[3].field_videos}
                      type="video/mp4"
                    />
                  </video>
                )}
                <Stream
                  controls
                  src={location.state.info.i.field_cloudflare_id}
                  section="wall"
                />
                {showSkipButton && (
                  <button
                    ref={skipButtonRef}
                    id="skip-button"
                    onClick={handleSkip}
                  >
                   {t("buttons.add")}
                  </button>
                )}
              </div>
              <div className="detail__header__container__alt">
                <hr />
                <label>{location.state.info.i.description}</label>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default InternalCoursePage;
