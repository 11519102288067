import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import arrow_left from "../../_images/ico-hfeatured-arrow-left.svg";
import arrow_right from "../../_images/ico-hfeatured-arrow-right.svg";

export default function SpeakersEvents({
  speakers,
  handlePagePrev,
  handlePageNext,
  pageNum,
  totalPages,
}) {
  //Language hooks
  const { t } = useTranslation("common");

  return (
    <div className="speakers_event_section container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="category__card">
          <h2>
            <span>{t("events.title")}</span>
            {t("events.speakers")}
          </h2>
        </div>
        <div className="search__results d-flex align-items-center events__speakers">
          <div className="buttons__courses d-flex align-items-center">
            <p>
              <strong>0{pageNum}</strong> / 0{totalPages}
            </p>
            <div className="nav__counter">
              <button onClick={() => handlePagePrev()}>
                <img itemProp="image" src={arrow_left} alt="arrow_left" />
              </button>
              <button onClick={() => handlePageNext()}>
                <img itemProp="image" src={arrow_right} alt="arrow_right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content center align-items-center">
        <ul>
          {Array.isArray(speakers?.rows) &&
            speakers.rows.map((item, index) => (
              <li
                className="d-flex justify-content-center align-items-start list_events_speakers"
                key={index}
              >
                {item && (
                  <>
                    <img src={item.field_photo} alt="spe1" />
                    <div>
                      <h4>{item.field_name_user}</h4>
                      <label>{item.field_state}</label>
                      <p>{item.field_description}</p>
                    </div>
                  </>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
