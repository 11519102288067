import React from "react";
import AuthorWall from "../contents/author-wall";
import { PostReactions } from "./post-reactions";
import { Stream } from "@cloudflare/stream-react";
import he from "he";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

import btnSave from "../../_images/ico-bookmark.svg";
import { articleService } from "../../_services";
import { PublishDate } from "./publish-date";

export const PostFeed = ({ post }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  const { id } = JSON.parse(localStorage.getItem("me"));

  const handleSave = () => {
    articleService.postSaveArticle(post.id, id);
  }

  return (
    <div className="feed__publication d-flex mb-4">
      <AuthorWall id={post.uid} name={post.display_name} date={post.date} />
      <article itemScope="" itemType="http://schema.org/Article">
      <div className="d-flex justify-content-between mb-3">
          <div
            className="name__author"
            itemProp="author"
            itemType="http://schema.org/Person"
          >
            <p>
              <Link
                to={`/${lang}/${t("home.profile")}/${String(
                  post.uid
                ).toLocaleLowerCase()}`}
              >
                {post.display_name}
              </Link>
            </p>
            <div className="details__autor d-flex">
              <PublishDate className="article__hour__tag" date={post.date} />
            </div>
          </div>
          <button className="btn-save_publication" title="save" onClick={handleSave}>
          <img src={btnSave} alt="btn-save"  />
        </button>
        </div>
        <div className="controls publication">
        <p className="post__text">{he.decode(post.summary)}</p>
       

        </div>
        {post.field_has_video === "True" ? (
          <Stream controls src={post.field_cloudflare_id} />
        ) : (
          <figure className="article__image__content">
            <Link
              className="image__btn"
              to={`/${lang}/${post.id}`}
              state={{ post: { post } }}
            >
              <img
                itemProp="image"
                src={post.image}
                alt={he.decode(post.title)}
                state={{ post: { post } }}
              />
            </Link>
          </figure>
        )}
        <div className="article__information">
          <h2 itemProp="headline">
            <Link to={`/${lang}/${post.id}`} state={{ post: { post } }}>
              {he.decode(post.title)}
            </Link>
          </h2>
        </div>

        <PostReactions type="field_like_dislike" post={post} actions={true} />
      </article>
    </div>
  );
};
