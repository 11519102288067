import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const VendorsService = ({ value, label, handler }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [service, setService] = useState([]);
  const options = [];

  useEffect(() => {
    /** Call Services  */
    taxonomys
      . getServicesVendors(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setService(response);
        }
      })
      .catch(console.warn);

    return () => {
      setService([]);
    };
  }, [lang, setService]);

  service.map((item) => {
    options.push({
      value: item.tid,
      label: t(item.name),
    });
  });

  return (
    <>
      <Select
        options={options}
        onChange={handler}
        defaultValue={{ label: value }}
      />
    </>
  );
};
