import React, { useEffect, useState } from "react";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import SEO from "../_components/common/seo";
import { Stream } from "@cloudflare/stream-react";

import he from "he";
import { path, url } from "../_helpers/decoupled-url";

import ico_back from "../_images/ico-back.svg";
import blue_arrow from "../_images/ico-arrow-blue.svg";
import "./styles.css";
import "../_styles/style.css";
import ico_toolbar from "../_images/ico-share-toolbar.svg";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useParams } from "@reach/router";
import { communityService } from "../_services";

export const DetailPageItem = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState([]);
  const [like, setLike] = useState("");
  const [shareUrl, setShareUrl] = useState("");
  const [str, setStr] = useState("");
  const [textoTags, setTextoTags] = useState("");

  let resultStr = str.replace(/(<([^>]+)>)/gi, "");

  const arrayTags = textoTags.split(",");
  const param = useParams();

  const clickShares = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setLoading(true);
    communityService
      .getInternalPost(lang, param.id)
      .then((res) => res.json())
      .then((response) => {
        const post = response[0];
        if (post) {
          setLoading(false);
          setPost(post);
          setLike(post.votes);
          setShareUrl(path(url(post.url)));
          setStr(he.decode(post.body));
          setTextoTags(String(post.tags));
        }
      })
      .catch(console.log);
  }, [lang, setPost]);

  let newUrl = shareUrl.replace('fined', '');
  newUrl += `/${lang}/${post.id}`;

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("menus.community")} />

      <main
        role="main"
        className="no__top"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section>
          <div className="container">
            <div className="detail__header d-flex flex-column">
              <div className="detail__header__container">
                <div className="detail__container">
                  <a
                    href={`/${lang}/${t("url.community")}`}
                    className="detail__back__btn"
                  >
                    <img src={ico_back} alt="Back" />
                    {t("menus.feed")}
                  </a>
                  <h2>{post.title}</h2>
                </div>
              </div>

              <div className="detail__arrow_blue">
                <img src={blue_arrow} alt="category" />
                {post.category}
              </div>

              <div className="detail__header__container__a">
                <div className="details__share">
                  {like !== false && (
                    <div className="interesting__btn__alt align-items-center others__links dropdown feed">
                      <a
                        href="/"
                        className="d-flex align-items-center"
                        onClick={clickShares}
                      >
                        <img src={ico_toolbar} alt="Reply to comment" />
                        <span>{t("contents.share")}</span>
                      </a>
                      <ul
                        className="share__options dropdown-list"
                        id="shared__list"
                      >
                        <div className="share__links">
                          <WhatsappShareButton url={newUrl}>
                            <p>
                              <i
                                className="fab fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                              {t("shared.share")} Whatsapp
                            </p>
                          </WhatsappShareButton>
                          <FacebookShareButton url={newUrl}>
                            <p>
                              <i
                                className="fab fa-facebook-f"
                                aria-hidden="true"
                              ></i>
                              {t("shared.share")} Facebook
                            </p>
                          </FacebookShareButton>
                          <LinkedinShareButton url={newUrl}>
                            <p>
                              <i
                                className="fab fa-linkedin-in"
                                aria-hidden="true"
                              ></i>
                              {t("shared.share")} Linkedin
                            </p>
                          </LinkedinShareButton>
                          <TwitterShareButton url={newUrl}>
                            <p>
                              <i
                                className="fab fa-twitter"
                                aria-hidden="true"
                              ></i>
                              {t("shared.share")} Twitter
                            </p>
                          </TwitterShareButton>
                        </div>
                      </ul>
                    </div>
                  )}
                </div>
                {post.is_video === "True" ? (
                  <Stream
                    controls
                    src={post.field_cloudflare_id}
                    section="wall"
                  />
                ) : (
                  <figure
                    className="article__image__content"
                    style={{ minHeight: "100%" }}
                  >
                    <img
                      itemProp="image"
                      src={post.image}
                      alt={post.title}
                      width="100%"
                      style={{ width: "100%" }}
                    />
                  </figure>
                )}
              </div>

              <div className="detail__header__container__alt">
                <hr />
                <label>{post.summary}</label>
              </div>
              <div className="detail__header__container__alt_block">
                <p>{resultStr}</p>
              </div>
              <div className="detail__header__container__alt_block">
                {arrayTags.map((tag, i) => (
                  <p className="detail__tags" key={i}>
                    {tag}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
