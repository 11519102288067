import React, { useState, useEffect } from "react";
import { taxonomys } from "../../_services/taxonomys.service";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export const VendorsBrand = ({ value, label, handler }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [brand, setBrand] = useState([]);
  const options = [];

  useEffect(() => {
    /** Call Services  */
    taxonomys
      . getBrandVendors(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setBrand(response);
        }
      })
      .catch(console.warn);

    return () => {
      setBrand([]);
    };
  }, [lang, setBrand]);

  brand.map((item) => {
    options.push({
      value: item.tid,
      label: t(item.name),
    });
  });

  return (
    <>
      <Select
        options={options}
        onChange={handler}
        defaultValue={{ label: value }}
      />
    </>
  );
};
