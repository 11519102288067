import React from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

export default function AboutPage() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="body__text about">
          <div className="container">
            <div className="frenetic__diference">
              <h2>What´s the ADN of a Tech Frenetic?</h2>
              <ul className="and__frenetic">
                <li>We are neutral</li>
                <li>We are tech passionate</li>
                <li>We are community lovers</li>
              </ul>
              <Link to={`/${lang}/${t("url.community")}`} className="btn-primary">
                Become frenetic
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
