import React, { useState } from "react";
import Author from "../contents/author";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { meetupService } from "../../_services";
import iconDelete from "../../_images/ico-delete-mycontent.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import icon from "../../_images/favicon.svg";

export default function MeetupCard({
  date,
  url,
  displayName,
  title,
  place,
  uid,
  onDelete,
  mid,
}) {
  const { t } = useTranslation("common");

  const gotoLink = (e, url) => {
    if (url && e.target.tagName.toLowerCase() !== "a") {
      window.open(url);
    }
  };
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { id } = JSON.parse(localStorage.getItem("me"));

  const userID = String(id);

  const deleteMeetups = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      // boton.current.classList.toggle("active");
      meetupService
        .deleteMeetups(mid)
        .then(() => {
          setLoading(false);
          setShow(false);
          onDelete(mid);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const showModal = () => {
    setShow(true);
  };

  return (
    <div className={`meetup__card ${url ? "link" : ""}`}>
      <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center">
        <div
          className="meetup__date col-12 col-sm-12 col-md-2 col-lg-2"
          onClick={(e) => gotoLink(e, url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="meet__month">{moment(date).format("MMM DD")}</span>
          <span className="meet__day">{moment(date).format("ddd")}</span>
        </div>
        <div
          className="meetup__detail col-12 col-sm-12 col-md-8 col-lg-8"
          onClick={(e) => gotoLink(e, url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>{title}</h2>
          <div className="d-flex align-items-center justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-start flex-column flex-sm-row flex-md-row flex-lg-row">
            <div className="profile__btn user">
              <Author info={false} id={uid} />
            </div>
            <span className="meet__author">
              {t("articles.by")}{" "}
              <span style={{ textDecoration: "none" }}>{displayName}</span>
            </span>
            {/* <Link
              to={`/${lang}/${t("home.profile")}/id=${String(id).toLocaleLowerCase()}`}
              className="meet__author"
            >
              
            </Link> */}

            <span className="article__cat__separator">•</span>
            <span className="meet__date">
              {moment(date).format("dddd, MMM DD")}
            </span>
            <span className="meet__location">{place}</span>
          </div>
        </div>
        {uid === userID && (
          <div className="meetup__detail col-12 col-sm-12 col-md-2 col-lg-2">
            <img src={iconDelete} alt="icon-delete" onClick={showModal} />
          </div>
        )}
      </div>
        <Modal
          backdrop="static"
          show={show}
          keyboard={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Modal.Header
            style={{ border: "none", width: "70%", margin: "0 auto" }}
          ></Modal.Header>
          <Modal.Body style={{ margin: "0 auto", width: "70%" }}>
            <div className="tittle__premium d-flex justify-content-center align-items-center">
              <img src={icon} alt="icon" />
              <h5>{t("delete.meetup")}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={deleteMeetups}
                className="me-5 button__delete"
              >
                {t("delete.yes")}
              </Button>
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={() => setShow(false)}
                className="me-5  button__delete"
              >
                {t("delete.not")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
}
