import React, { useEffect, useState } from "react";
import EventFeatured from "../ui/event-featured";
import { useTranslation } from "react-i18next";
import { eventService } from "../../_services/event.service";
import * as moment from "moment";
import { path } from "../../_helpers";
import he from "he";
import "react-multi-carousel/lib/styles.css";

export default function FeaturedEvents({ onClick }) {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [data, setData] = useState([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsFeatured(lang)
      .then((res) => res.json())
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  return (
    <div className="container">
      <div className="featured__heading d-flex justify-content-between">
        <h3 className="title__home bold">
          <span className="title__highlight">{t("events.featured_blue")}</span>
          {t("events.featured_black")}
        </h3>
      </div>
      <div className="featured__carousel">
        {data.slice(0, 3).map((dataArticle) => (
          <div
            className="small__column__articles featured_event_item"
            key={dataArticle.nid}
          >
            <EventFeatured
              slug={dataArticle.field_path}
              img={path(dataArticle.field_image)}
              category={dataArticle.field_principal_category}
              title={he.decode(dataArticle.field_name_evente)}
              location={dataArticle.field_location}
              startDate={moment(dataArticle.field_start_date).format(
                "MMM DD, YYYY"
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
