import React, { useEffect, useRef, useState } from "react";
import { articleService, communityService } from "../../_services";
import "../../css/post-actions.css";
import icon from "../../_images/favicon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import btnDelete from "../../_images/ico-delete-mycontent.svg";
import btnSave from "../../_images/ico-bookmark.svg";

export const PostActions = ({ post, onDelete }) => {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [loading, setLoading] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [listPostSave, setListPostSave] = useState([]);

  const boton = useRef(null);

  /**
   *
   * @param {*} e
   */

  /**
   *
   * @param {*} e
   */

  /**
   *
   * @param {*} e
   */
  const handleDelete = (e) => {
    e.preventDefault();
    setDeleteBtn(true);
    //
  };

  const hiddenModal = () => {
    setDeleteBtn(false);
  };

  const deletePost = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      boton.current.classList.toggle("active");
      communityService
        .deleteArticle(post.id)
        .then(() => {
          setLoading(false);
          onDelete(post);
          setDeleteBtn(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const { id } = JSON.parse(localStorage.getItem("me"));

  const handleSave = () => {
    articleService.postSaveArticle(post.id, id);
  };

  useEffect(() => {
    if (id) {
      articleService
        .getListSaveArticles(lang, id)
        .then((res) => res.json())
        .then((response) => {
          const articleIds = response.articles.map((article) => article.id);
          setListPostSave(articleIds);
        })
        .catch((error) => console.log(error));
    }
  }, [setListPostSave, id]);

  const isPostSaved = listPostSave.includes(post.id);

  return (
    <div className="byme__author d-flex justify-content-between align-items-center">
      <div className="controls-publication">
        <button
          className="btn-save_publication"
          title="save"
          onClick={handleSave}
          disabled={isPostSaved}
        >
          <img src={btnSave} alt="btn-save" />
        </button>
        <a
          href="#"
          className="btn-delete_publication"
          onClick={handleDelete}
          ref={boton}
          title="delete"
        >
          <img src={btnDelete} alt="btn-delete" />
        </a>
      </div>
      {deleteBtn && (
        <Modal
          backdrop="static"
          show={deleteBtn}
          keyboard={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Modal.Header
            style={{ border: "none", width: "70%", margin: "0 auto" }}
          ></Modal.Header>
          <Modal.Body style={{ margin: "0 auto", width: "70%" }}>
            <div className="tittle__premium d-flex justify-content-center align-items-center">
              <img src={icon} alt="icon" />
              <h5>{t("delete.text")}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={deletePost}
                className="me-5 button__delete"
              >
                {t("delete.yes")}
              </Button>
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={hiddenModal}
                className="me-5 button__delete"
              >
                {t("delete.not")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
