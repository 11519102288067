import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { contentsConstants } from "../../_constants";
import { modalActions } from "../../_actions";
import { articleService } from "../../_services";
import { withTranslation } from "react-i18next";
import { SearchFilters } from "../search/search-filters";
import { SearchInput } from "../search/search-input";
import { LoadMore } from "../contents/load-more";
import { CategoryLink } from "../ui/category-link";
import { Spinner } from "../ui/spinner";

import logo from "../../_images/techfrenetic-logo.svg";
import ArticleSearch from "../ui/article-search";
import UserSearch from "../ui/user-search ";
import GroupSearch from "../ui/group-search";
import VendorSearch from "../ui/vendor-search";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: "content",
      executed: false,
      query: "",
      categories: [],
      articles: [],
      total: 0,
      page: 0,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    //This code is executed due preset by external search queries.
    if (prevProps.value !== value) {
      const results = typeof value === "string";

      //Reset criterias.
      this.setState({
        types: "content", //Search default in content.
        query: value,
        executed: results,
        articles: [],
        categories: [],
        page: 0,
      });

      //Triggers search if any query is predefined.
      if (results) {
        this.fetchResults(value, null, 0);
      }
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, executed: false });
  };

  handleOptionChange = (e) => {
    this.setState({ types: e.target.value, executed: false });
  };

  handleCategoriesChange = (categories) => {
    this.setState({ articles: [], categories: categories, page: 0 });
    this.fetchResults(null, categories, 0);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ articles: [], page: 0 });
    this.fetchResults(null, null, 0);
  };

  //Execute service call
  fetchResults = (value, categories, page) => {
    value = value || this.state.query;
    categories = categories || this.state.categories;
    page = page === 0 ? 0 : page || this.state.page;

    //Check type of result to look for.
    if (this.state.types === "content") {
      const lang = this.props.i18n.language;

      /** Call Services  */
      this.setState({ loading: true });
      articleService
        .getArticleSearch(lang, value, categories, page)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.setState({
              total: response.results,
              articles: this.state.articles.concat(response.articles),
              executed: true,
              loading: false,
            });
          }
        })
        .catch(console.log);
    }
    if (this.state.types === "users") {
      const lang = this.props.i18n.language;

      /** Call Services  */
      this.setState({ loading: true });
      articleService
        .getUserSearch(lang, value)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.setState({
              total: response.length,
              articles: response,
              executed: true,
              loading: false,
            });
          }
        })
        .catch(console.log);
    }
    if (this.state.types === "groups") {
      const lang = this.props.i18n.language;

      /** Call Services  */
      this.setState({ loading: true });
      articleService
        .getGroupSearch(lang, value)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.setState({
              total: response.length,
              articles: response,
              executed: true,
              loading: false,
            });
          }
        })
        .catch(console.log);
    }

    if (this.state.types === "vendors") {
      const lang = this.props.i18n.language;

      /** Call Services  */
      this.setState({ loading: true });
      articleService
        .getVendorSearch(lang, value)
        .then((res) => res.json())
        .then((response) => {
          if (response) {
            this.setState({
              total: response.length || response.rows.length,
              articles: response.rows,
              executed: true,
              loading: false,
            });
          }
        })
        .catch(console.log);
    }
  };

  //Handle click.
  handleMore = () => {
    const page = this.state.page + 1;
    this.fetchResults(null, null, page);
    this.setState({ page: page });
  };

  render() {
    const { hideSearch, search: visibility } = this.props;
    const { t, i18n } = this.props;
    const lang = i18n.language;

    return (
      <Modal
        className="fullsize"
        show={visibility}
        onHide={hideSearch}
        animation={true}
      >
        <div id="search">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hideSearch}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-dialog modal-lg" role="document">
            <div className="search__heading">
              <img itemProp="image" src={logo} alt="Search here" />
              <h2 itemProp="headline">{t("search.looking")}</h2>
            </div>
            <form className="search__form" onSubmit={this.handleSubmit}>
              <div className="search__filter__tabs">
                <input
                  name="category__filter"
                  id="content"
                  type="radio"
                  value="content"
                  checked={this.state.types === "content"}
                  onChange={this.handleOptionChange}
                />
                <label htmlFor="content">{t("search.tab_content")}</label>
                <input
                  name="category__filter"
                  id="users"
                  type="radio"
                  value="users"
                  checked={this.state.types === "users"}
                  onChange={this.handleOptionChange}
                />
                <label htmlFor="users">{t("search.tab_users")}</label>
                <input
                  name="category__filter"
                  id="groups"
                  type="radio"
                  value="groups"
                  checked={this.state.types === "groups"}
                  onChange={this.handleOptionChange}
                />
                <label htmlFor="groups">{t("search.tab_groups")}</label>
                <input
                  name="category__filter"
                  id="vendors"
                  type="radio"
                  value="vendors"
                  checked={this.state.types === "vendors"}
                  onChange={this.handleOptionChange}
                />
                <label htmlFor="vendors">{t("search.tab_vendors")}</label>
              </div>

              <SearchInput
                query={this.state.query}
                handleChange={this.handleChange}
                handleClear={() => this.setState({ executed: false })}
                handleSubmit={this.handleSubmit}
              />

              {this.state.executed && (
                <SearchFilters setFilters={this.handleCategoriesChange} />
              )}
            </form>

            {!this.state.executed && (
              <>
                <div className="popular__s">{t("search.populars")}</div>
                {lang === "en" && (
                  <div className="quick__cats">
                    <CategoryLink
                      className="quick__cats__btn"
                      name="Cybersecurity"
                    />
                    <CategoryLink
                      className="quick__cats__btn"
                      name="Aplications"
                    />
                    <CategoryLink className="quick__cats__btn" name="Cloud" />
                    <CategoryLink className="quick__cats__btn" name="Storage" />
                  </div>
                )}

                {lang === "es" && (
                  <div className="quick__cats">
                    <CategoryLink
                      className="quick__cats__btn"
                      name="Ciberseguridad"
                    />
                    <CategoryLink
                      className="quick__cats__btn"
                      name="Aplicaciones"
                    />
                    <CategoryLink className="quick__cats__btn" name="La Nube" />
                    <CategoryLink
                      className="quick__cats__btn"
                      name="Almacenamiento"
                    />
                  </div>
                )}
              </>
            )}

            {this.state.executed && (
              <>
                <h4 className="search__results">{`${this.state.query}: ${
                  this.state.total
                } ${t("search.results")}${
                  this.state.total !== 1 ? "s" : ""
                }`}</h4>
                {this.state.types === "content" && (
                  <div className="search__results__box d-flex flex-wrap">
                    {this.state.articles.map((article, id) => (
                      <ArticleSearch
                        article={article}
                        key={id}
                        onClick={this.props.hideSearch}
                      />
                    ))}
                  </div>
                )}
                {this.state.types === "users" && (
                  <div className="search__results__box d-flex flex-wrap">
                    {this.state.articles.map((article, id) => (
                      <UserSearch
                        article={article}
                        key={id}
                        onClick={this.props.hideSearch}
                      />
                    ))}
                  </div>
                )}
                {this.state.types === "groups" && (
                  <div className="group__cards__container d-flex flex-wrap create__group__content group-exist creation__group__form max">
                    {this.state.articles.map((article, id) => (
                      <GroupSearch
                        article={article}
                        id={id}
                        hideSearch={this.props.hideSearch}
                      />
                    ))}
                  </div>
                )}

                {this.state.types === "vendors" && (
                  <div className="group__cards__container d-flex flex-wrap create__group__content group-exist creation__group__form max">
                    {Array.isArray(this.state.articles) ? (
                      this.state.articles.map((article, id) => (
                        <VendorSearch
                          article={article}
                          id={id}
                          hideSearch={this.props.hideSearch}
                          key={id}
                        />
                      ))
                    ) : (
                      <p>No se encontraron resultados.</p>
                    )}
                  </div>
                )}

                {
                  /* Load More button */
                  !this.state.loading ? (
                    <LoadMore
                      page={this.state.page}
                      totalItems={this.state.total}
                      itemsByPage={contentsConstants.RESULTS_BY_SEARCH}
                      onClick={this.handleMore}
                    />
                  ) : (
                    <Spinner />
                  )
                }
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

function mapState(state) {
  const { search, query } = state.modals;
  let value = query;
  return { search, value };
}

const actionCreators = {
  hideSearch: modalActions.hideSearch,
  showSearch: modalActions.showSearch,
};

const connectedSearch = connect(
  mapState,
  actionCreators
)(withTranslation("common")(Search));
export { connectedSearch as Search };
