import { authHeader } from "../_helpers";
import { appConstants } from "../_constants";

export const vendorService = {
  getFeaturedVendors,
  getAllProviders,
  getAllProviders2,
  getDistributors,
  getPartners,
  getProvider,
  getVendor,
  getVendorsList,
  getVendorsDescription
};

/**
 *
 * @param {*} lang
 */
function getFeaturedVendors(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/vendors?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} lang
 */
function getDistributors(lang, filters) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/distributors-featured?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} lang
 */
function getPartners(lang, filters) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/partners-featured?_format=json`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} lang
 */
function getAllProviders(lang, filters) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  //Translate filters
  let query = "";
  const { q, d, c, v, k } = filters;
  if (q.length) {
    query += `&title=${q}&desc=${q}`;
  }
  if (d.length) {
    query += `&where=${d}`;
  }
  if (c.length) {
    query += `&cat[]=${String(c).split(",").join("&cat[]=")}`;
  }
  if (v.length) {
    query += `&vendor=${v}`;
  }
  if (k.length) {
    let opc = String(k).split(",");
    //Vendor
    if (opc.indexOf("1") >= 0) {
      query += `&content[]=vendor`;
    }

    //Partner
    if (opc.indexOf("2") >= 0) {
      query += `&content[]=partner`;
    }

    //Distributor
    if (opc.indexOf("3") >= 0) {
      query += `&dist=1`;
    }
  }

  //Setup url and get data
  const url = `${appConstants.API}/${lang}/v1/partners?_format=json${query}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getAllProviders2(lang, filters, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let query = "";
  const { q, d, s, b } = filters;
  if (q.length) {
    query += `supplier=${q}`;
  }
  if (d.length) {
    query += `country=${d}`;
  }
  if (b.length) {
    query += `brand=${b}`;
  }
  if (s.length) {
    query += `service=${s}`;
  }
  const url = `${appConstants.API}/${lang}/v1/search-supplier?${query}&page=${page}`;
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 *
 * @param {*} lang
 */
function getProvider(lang, slug) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/partner?_format=json&alias=${slug}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} lang
 */
function getVendor(lang, slug) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${appConstants.API}/${lang}/v1/search-supplier?nid=${slug}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} response
 */
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;

      //Filter Drupal errors.
      let result = String(
        error
          .toString()
          .toLocaleLowerCase()
          .match(/[a-z]+/g)
      ).replace(/,/g, "_");

      return Promise.reject(result);
    }
    return data;
  });
}

function getVendorsList(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
      `${appConstants.API}/${lang}/v1/search-supplier`,
    requestOptions
  );
}

function getVendorsDescription(lang) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
      `${appConstants.API}/${lang}/v1/description-vendors`,
    requestOptions
  );
}