import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import { VendorsService } from "../ui/vendors-service";
import { VendorsBrand } from "../ui/vendors-brand";

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const SearchVendorForm = ({ className, onSubmit }) => {
  const { t } = useTranslation("common");
  const [filters, setFilters] = useState({
    q: useQuery("q") || "",
    d: useQuery("d") || "",
    s: useQuery("s") || "",
    b: useQuery("b") || "",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    onSubmit(filters);
  };

  const changeWhat = (e) => {
    let data = { ...filters };
    data.q = e.target.value;
    setFilters(data);
  };

  const changeWhere = (e) => {
    let data = { ...filters };
    data.d = e.target.value;
    setFilters(data);
  };

  const changeService = (e) => {
    let data = { ...filters };
    data.s = e.value;
    setFilters(data);
  };

  const changeBrand = (e) => {
    let data = { ...filters };
    data.b = e.value;
    setFilters(data);
  };

  return (
    <>
      <form
        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-end justify-content-center form-vendors"
        onSubmit={handleSearch}
      >
        <div className="d-flex flex-column text-left vendor-camp">
          <label className="p-0">{t("vendors.looking")}</label>
          <input
            type="text"
            placeholder={t("vendors.looking_desc")}
            value={filters.q}
            onChange={changeWhat}
          />
        </div>
        <div className="d-flex flex-column text-left vendor-camp">
          <label htmlFor="where" className="p-0">
            {t("vendors.where")}
          </label>
          <input
            type="text"
            placeholder={t("vendors.where_desc")}
            value={filters.d}
            onChange={changeWhere}
          />
        </div>
        <div className="d-flex flex-column text-left vendor-camp vendor-service">
          <label className="p-0 no__floating">{t("vendors.service")}</label>
          <VendorsService
            handler={changeService}
            className=""
          />
        </div>
        <div className="d-flex flex-column text-left vendor-camp vendor-service">
          <label className="p-0 no__floating">{t("vendors.brand")}</label>
          <VendorsBrand
            handler={changeBrand}
            className=""
          />
        </div>
        <div className="d-flex text-left vendor-camp ml-3">
          <a
            className="d-flex align-items-center justify-content-center"
            href="/"
            onClick={handleSearch}
          >
            {t("buttons.search")}
          </a>
          <button
            type="submit"
            style={{ width: 0, padding: 0, height: 0, opacity: 0 }}
          ></button>
        </div>
      </form>
    </>
  );
};
