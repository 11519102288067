import React from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import HomeCarrousel from "../_components/ui/home-carrousel";
import CardBox from "../_components/ui/card-box";
import { useTranslation } from "react-i18next";

import FeaturedEvents from "../_components/lists/featured-events";

import { modalActions } from "../_actions";
import { modalConstants } from "../_constants";

const HomePage = ({ token, recoveryPassword }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  //Check password token reset.
  if (token) {
    navigate(`/${lang}`);
    recoveryPassword(modalConstants.RESET_STEP1, token);
  }

  return (
    <Layout>
      <SEO lang={lang} title={t("home.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        {/* Carousel */}
        <HomeCarrousel />

        {/* Cards with CTAs */}
        <section className="category__cards">
          <div className="container row">
            <div className="category__card col-12 col-sm-12 col-md-12 col-lg-4">
              <CardBox
                title={[
                  <span className="yellow" key="cta1">
                    {t("home.cta1_feature")}
                  </span>,
                  t("home.cta1_title"),
                ]}
                body={t("home.cta1_body")}
                cta={t("home.cta1_link")}
                link={`/${lang}/${t("url.community")}`}
              />
            </div>
            <div className="category__card col-12 col-sm-12 col-md-12 col-lg-4">
              <CardBox
                title={[
                  <span key="cta2">{t("home.cta2_feature")}</span>,
                  t("home.cta2_title"),
                ]}
                body={t("home.cta2_body")}
                cta={t("home.cta2_link")}
                link={`/${lang}/${t("url.contents")}`}
              />
            </div>
            <div className="category__card col-12 col-sm-12 col-md-12 col-lg-4">
              <CardBox
                title={[
                  <span key="cta3">{t("home.cta3_feature")}</span>,
                  t("home.cta3_title"),
                ]}
                body={t("home.cta3_body")}
                cta={t("home.cta3_link")}
                link={`/${lang}/${t("url.vendors")}`}
              />
            </div>
          </div>
        </section>

        {/* Featured articles */}
        {/* <section className="featured__content__home">
          <FeaturedArticles />
        </section> */}
        <section className="featured__events">
          <FeaturedEvents />
        </section>
      </main>
    </Layout>
  );
};

const actionCreators = {
  recoveryPassword: modalActions.showRecoveryPassword,
};

const connectedHomePage = connect(null, actionCreators)(HomePage);
export { connectedHomePage as HomePage };
