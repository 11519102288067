import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { HorizontalAd } from "../_components/ads/horizontal-ad";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";

import search_image from "../_images/main-search-image.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/premium_modal.css";
import "../css/contentsPage.css";
import logo_courses from "../_images/courses.png";
import arrow_left from "../_images/ico-hfeatured-arrow-left.svg";
import arrow_right from "../_images/ico-hfeatured-arrow-right.svg";

import { articleService, userService } from "../_services";

import { Courses } from "../_components/lists/courses";
import { Link } from "@reach/router";

const ContentsPage = ({ me }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [courses, setCourses] = useState([]);
  const [listCourse, setListCourse] = useState([]);
  const handleClose = () => setShow(false);
  let pageNum = page + 1;

  useEffect(() => {
    articleService
      .getCoursesGroup(lang, page)
      .then((res) => res.json())
      .then((response) => {
        setCourses(response.rows);
        setListCourse(response.pager.total_pages);
      })
      .catch((error) => console.log(error));
  }, [page]);

  useEffect(() => {
    /** Call Services  */
    userService
      .summary(lang, me.id)
      .then((res) => res.json())
      .then((response) => {
        if (response[0].Roles !== "Premium") {
          setShow(true);
        }
      })
      .catch(console.log);
  }, [lang]);

  const handlePagePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handlePageNext = () => {
    if (pageNum < listCourse) {
      setPage(page + 1);
    }
  };

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("contents.title")} />
      <main
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Modal.Header style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
              <div className="tittle__premium">
                <h1 className="highlighted text-center">
                  <span>{t("premium.modal_title_1")}</span>
                  {t("premium.modal_title_2")}
                </h1>
              </div>
              <p>{t("premium.modal_text")}</p>
              <Button
                variant="primary"
                href={`/${lang}/${t("url.premium")}`}
                className="modal__button"
              >
                {t("premium.modal_button")}
              </Button>
            </Modal.Body>
          </Modal>
        <section className="main__search__box">
          <div className="container d-flex align-items-center">
            <div className="col-lg-6 col-md-12 search__box__contain">
              <span className="form__title">{t("contents.title")}</span>
              <h1 className="main__title">{t("contents.subtitle")}</h1>

              {/* Beginning of interest search form */}
              {/* <SearchContents /> */}
              <p className="text_content">{t("contents.text_content")}</p>
            </div>
            <div className="col-md-6 d-none d-lg-block">
              <img
                itemProp="image"
                src={search_image}
                alt="Lear and collaborate with experts"
              />
            </div>
          </div>
        </section>

        <section className="courses__group">
          <div className="container">
            <div className="courses__heading">
              <img
                itemProp="image"
                src={logo_courses}
                alt="logo_courses"
                className="mr-5"
              />
              <div className="navigation__courses d-flex align-items-center">
                <p className="text_content-course">
                  {pageNum} / {listCourse + 1}
                </p>
                <div className="buttons__courses d-flex align-items-center">
                  <div className="nav__counter">
                    <button onClick={() => handlePagePrev()}>
                      <img itemProp="image" src={arrow_left} alt="arrow_left" />
                    </button>
                    <button onClick={() => handlePageNext()}>
                      <img
                        itemProp="image"
                        src={arrow_right}
                        alt="arrow_right"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className="text_content_alt">{t("contents.text_content")}</p>
            <div className="courses__carousel">
              <ul className="courses_list">
                {courses &&
                  courses.map((i, index) => (
                    <li
                      key={index}
                      className="d-flex justify-content-center courses_list-item"
                    >
                      <Link
                        to={`/${lang}/${t("url.course")}/${i.nid}`}
                        state={{ id: i.uid }}
                      >
                        <Courses i={i} />
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Beginning feature section home */}

        {/* Ad */}
        <HorizontalAd id="Main Banner" />

        {/* Beginning lastest videos section */}
      </main>
      <MobileMenu selected="skills" withFade={true} />
    </Layout>
  );
};

export default ContentsPage;
