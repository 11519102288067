import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";

import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import EventBanner from "../_components/ui/event-banner";
import EventThumb from "../_components/ui/event-thumb";
import { eventService } from "../_services";
import { path } from "../_helpers";


import e1 from "../_images/e1.png";
import e2 from "../_images/e2.png";
import e3 from "../_images/e3.png";
import e4 from "../_images/e4.png";
import ico_back from "../_images/ico-hfeatured-arrow-left.svg";

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  let value = search.get(queryParam);
  if (value) {
    return value.split(",");
  }
  return [];
};

const EventsSearchUp = () => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [info] = useState({});
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);

  //Filters
  const [filters, setFilters] = useState({
    k: useQuery("k"),
    v: useQuery("v"),
    c: useQuery("c"),
    t: useQuery("t"),
    q: useQuery("q"),
    d: useQuery("d"),
    s: useQuery("s"),
    b: useQuery("b"),
  });

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsFeatured(lang)
      .then((res) => res.json())
      .then((response) => {
        const articleImportant = [response.shift()];
        setData(articleImportant);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  useEffect(() => {
    eventService
      .getSearchUpcommingEvent(lang, filters)
      .then((res) => res.json())
      .then((response) => {
        setEvents(response);
      });
  }, [filters]);

  return (
    <Layout>
      <SEO lang={lang} title={t("events.title")} />
      <main
        className="contents__inner"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="event__head">
          <div className="banner__events d-flex flex-column">
            <div className="contains__banner__first d-flex justify-content-center container">
              <div className="text__banner d-flex justify-content-center align-items-center">
                <div className="title">
                  <span>Tech</span>
                  <h1>{t("events.title")}</h1>
                </div>
                <div className="body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Velit erat velit ornare aliquam, consect. Edit consectetum
                    alit ediam venut!{" "}
                  </p>
                </div>
              </div>
              <div className="images__banner">
                <img src={e1} alt="event1" className="img1" />
                <img src={e2} alt="event2" className="img2" />
                <img src={e3} alt="event3" className="img3" />
              </div>
            </div>

            <div className="contains__banner__two d-flex container align-items-end">
              <div className="contact">
                {/* Beginning of top trending section */}
                {data.map((dataInportant) => (
                  <EventBanner
                    slug={dataInportant.field_path}
                    key={dataInportant.field_name_evente + "banner"}
                    img={path(dataInportant.field_image)}
                    title={dataInportant.field_name_evente}
                    location={dataInportant.field_location}
                    startDate={moment(dataInportant.field_start_date).format(
                      "MMM DD, YYYY"
                    )}
                  />
                ))}
              </div>
              <div className="background">
                <img src={e4} alt="img4" />
              </div>
            </div>
          </div>
        </section>

        <section className="featured__events recent_events">
          <div className="container">
            <h3 className="title__home bold mb-5">
              <span className="title__highlight">
                {t("events.recents_blue_result")}
              </span>
              {t("events.recents_blue_search")}
            </h3>
            <a
              href={`/${lang}/${t("url.events")}`}
              className="detail__back__btn"
            >
              <img src={ico_back} alt="Back" />
              {t("menus.events")}
            </a>
            <div className="featured__allitems">
              {events.map((item) => (
                <div className="small__column__articles" key={item.nid}>
                  {/* <div>{item.label}</div> */}
                  <EventThumb
                    slug={item.field_path}
                    img={path(item.field_image)}
                    title={item.field_name_evente}
                    location={item.field_location}
                    startDate={moment(item.field_start_date).format(
                      "MMM DD, YYYY"
                    )}
                    category={item.field_principal_category}
                    description={item.field_summary_short}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default EventsSearchUp;
