import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { navigate } from "@reach/router";
import { Spinner } from "../_components/ui/spinner";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { SearchVendorForm } from "../_components/forms/search-vendor-form";
import { vendorService } from "../_services";
import { useLocation, Link } from "@reach/router";

import ico_search_partners from "../_images/ico-search-partner-darkblue.svg";
import arrow_more from "../_images/ico-hfeatured-arrow-right.svg";
import arrow_left from "../_images/ico-hfeatured-arrow-left.svg";
import arrow_right from "../_images/ico-hfeatured-arrow-right.svg";

/**
 * Custom hook to return params as array.
 * @param {*} queryParam
 */
export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  let value = search.get(queryParam);
  if (value) {
    return value.split(",");
  }
  return [];
};

const SearchVendorPage = ({ categories }) => {
  //Language
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [description, setDescription] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  let result = vendorList.slice(0, 3);

  //Filters
  const [filters, setFilters] = useState({
    k: useQuery("k"),
    v: useQuery("v"),
    c: useQuery("c"),
    t: useQuery("t"),
    q: useQuery("q"),
    d: useQuery("d"),
    s: useQuery("s"),
    b: useQuery("b"),
  });

  //States
  const [vendors, setVendors] = useState([]);
  const [providers, setProviders] = useState([]);

  //Pagination
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [vendorPage, setVendorPage] = useState([]);
  let pageNum = page + 1;

  const handlePagePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handlePageNext = () => {
    if (pageNum < vendorPage.total_pages) {
      setPage(page + 1);
    }
  };

  //Load Vendors
  useEffect(() => {
    let provider = JSON.parse(sessionStorage.getItem("vendors"));
    if (!provider) {
      vendorService
        .getFeaturedVendors(lang)
        .then((result) => {
          sessionStorage.setItem("vendors", JSON.stringify(result));
          setVendors(result);
        })
        .catch(console.log);
    } else {
      setVendors(provider);
    }

    vendorService
      .getVendorsList(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setVendorList(response.rows);
        }
      })
      .catch(console.warn);

    vendorService
      .getVendorsDescription(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setDescription(response[0]);
        }
      })
      .catch(console.warn);

    return () => {
      setVendorList([]);
      setDescription([]);
    };
  }, [lang, setVendorList, setDescription]);

  //Load Partners
  useEffect(() => {
    vendorService.getAllProviders2(lang, filters, page).then((response) => {
      setTotal(Number(response.pager.total_items));
      setVendorPage(response.pager);
      setProviders(response.rows);
    });
  }, [filters, page]);

  /**
   * Receive submit on search.
   * @param {*} f Filters selected
   */
  const handleChangeFilters = (f) => {
    const ff = { ...filters, ...f };
    setFilters(ff);

    let query = "";
    Object.keys(ff).map((idx) => {
      if (ff[idx].length) {
        query += !query ? `?${idx}=${ff[idx]}` : `&${idx}=${ff[idx]}`;
      }
    });

    navigate(`/${lang}/${t("url.vendors")}/${t("url.search")}${query}`);
  };

  /**
   * Execute api to retrieve partners and vendors
   * @param {*} filters
   */

  /**
   * Move to next page.
   */
  /**
   * Notice dropdown sort change
   */

  //Loading dependencies
  if (!categories && !vendors) {
    return <Spinner />;
  }

  //Show page
  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="partners__search__box">
          <div className="d-flex align-items-center">
            <div className="col-lg-12 partners__search__form">
              <h1>{t("vendors.title")}</h1>
              <p>{t("vendors.expand_intro")}</p>
              <div>
                <a href="/" className="form__partner__trigger">
                  <img src={ico_search_partners} alt={t("vendors.looking")} />
                  <span>{t("vendors.looking")}</span>
                </a>

                <SearchVendorForm
                  className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center justify-content-center"
                  onSubmit={handleChangeFilters}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="category__cards__partners partners__results pt-2">
          <div className="container d-flex row">
            <div className="vendors__listing">
              <div className="search__filters__partners justify-content-between">
                <div className="search__results d-flex justify-content-between justify-content-sm-between align-items-end">
                  <div>
                    <span className="sr__1">{t("vendors.search_results")}</span>
                    <span className="sr__2">{total}</span>
                  </div>
                  <div className="buttons__courses d-flex align-items-center">
                    <p>
                      <strong>0{pageNum}</strong> / 0{vendorPage.total_pages}
                    </p>
                    <div className="nav__counter">
                      <button onClick={() => handlePagePrev()}>
                        <img
                          itemProp="image"
                          src={arrow_left}
                          alt="arrow_left"
                        />
                      </button>
                      <button onClick={() => handlePageNext()}>
                        <img
                          itemProp="image"
                          src={arrow_right}
                          alt="arrow_right"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary filter"
                provider-toggle="modal"
                provider-target="#_1"
              >
                {t("buttons.filter")}
              </button>
              <div className="partners__cards">
                {providers.map((provider, idx) => (
                  <div className="" key={idx}>
                    <article itemScope="" itemType="http://schema.org/Article">
                      <Link
                        to={`/${lang}/${t("url.internal-vendor")}/${
                          provider.nid
                        }`}
                      >
                        <div className="partner__information__box">
                          <div className="partner__img d-flex align-items-end justify-content-between">
                            <img
                              src={provider.field_photo}
                              alt="image-vendor"
                            />
                          </div>
                          <div className="partner__information">
                            <h2>{provider.title}</h2>
                            <label>{provider.field_services_vendors}</label>
                          </div>
                        </div>
                      </Link>
                    </article>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/** Cambios */}

        {/** Body vendor */}
        <section className="body-vendors container">
          <div className="d-flex justify-content-center align-items-center gap-50 content-vendors">
            <div className="images-portada-vendors">
              <div className="d-flex ">
                <div className="section-1 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src={description.field_image_one}
                    alt="v1"
                    className="img1-vendors"
                  />
                  <img
                    src={description.field_image_two}
                    alt="v2"
                    className="img2-vendors"
                  />
                </div>
                <img
                  src={description.field_image_three}
                  alt="v3"
                  className="img3-vendors"
                />
              </div>
            </div>
            <div className="text-body-vendors">
              <p>{description.body}</p>
            </div>
          </div>
        </section>

        {/** List vendor */}
        <section className="list-vendors container">
          <div className="list-category-cards  d-flex justify-content-center flex-column">
            <div className="category__card">
              <h2>
                <span>Our newest</span>
                vendors
              </h2>
            </div>
            <div className="cards_vendors d-flex justify-content-between">
              {result.map((i, index) => (
                <div key={index} className="card_vendor">
                  <img src={i.field_photo} alt="vendors-image" />
                  <h2>{i.title}</h2>
                  <label>{i.field_services_vendors}</label>
                  <p>{i.body}</p>
                  <div className="vendor_more">
                    <p>Learn more</p>
                    <img src={arrow_more} alt="arrow_more" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/** Contact vendor */}
        <section className="container contact-vendors">
          <div className="contact-vendor d-flex justify-content-center flex-column text-center">
            <h1>Do you want to see your business here?</h1>
            <p>We will be glad to help you!</p>
            <button>Contact us</button>
          </div>
        </section>
      </main>
      <MobileMenu selected="vendors" withFade={true} />
    </Layout>
  );
};

function mapState(state) {
  const { config } = state.app;
  if (config) {
    return { categories: config.categories };
  }

  return { categories: null };
}

const connectedSearchVendorPage = connect(mapState)(SearchVendorPage);
export { connectedSearchVendorPage as SearchVendorPage };
