import React, { useState } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Avatar } from "../users/avatar";
import { communityService } from "../../_services";
import { Spinner } from "./spinner";
import { modalActions } from "../../_actions";
import * as moment from "moment";

import img1 from "../../_images/feed/img1.png";
import img2 from "../../_images/feed/img2.png";
import img3 from "../../_images/feed/img3.png";

const PostSomething = ({ user, me, onMessage, showAddArticle, group }) => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [something, setSomething] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleShowVideo = (e) => {
    e.preventDefault();
    showAddArticle("video");
  };

  const handleShowArticle = (e) => {
    e.preventDefault();
    showAddArticle("article");
    if (group === "true") {
      localStorage.setItem("groupArticle", JSON.stringify(true));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSubmit(value.length > 0);
    setSomething(value);
  };

  const handleShareUpdate = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const d = new Date();
    const obj = {
      id: 0,
      title: something,
      display_name: me.display_name,
      user: me.name,
      role: me.role,
      type: "Post",
      date: moment(d).format("YYYY-MM-DD HH:mm:ss"),
      comments: "0",
      views: "0",
      votes: "0",
      likes: "0",
    };

    setLoading(true);
    setError("");
    communityService
      .addPost(lang, obj)
      .then((response) => {
        //Reset form.
        obj.id = response.nid[0].value;
        setSubmit(false);
        setSomething("");
        setLoading(false);
        onMessage(obj);
      })
      .catch((msg) => {
        setError(msg);
        setLoading(false);
      });
  };

  return (
    <div className="profile__header community">
      <h3 className="tabs__container__title">{t("feed.something-title")}</h3>
      <div className="content__somethings d-flex justify-content-center">
        <div className="tabs__container create__content">
          <ul
            className="nav nav-tabs draggable draggable-center ui-draggable ui-draggable-handle"
            role="tablist"
            style={{ position: "relative", width: "470.923px" }}
          >
            <li role="presentation">
              <a
                href="/"
                aria-controls="update"
                role="tab"
                data-toggle="tab"
                onClick={handleShareUpdate}
              >
                <img src={img1} alt="img1" />
              </a>
            </li>
            <li role="presentation">
              <a
                style={{ paddingLeft: "40px" }}
                className="video__c"
                href="#profile"
                aria-controls="video"
                role="tab"
                data-toggle="tab"
                onClick={handleShowVideo}
              >
                <img src={img2} alt="img2" />
              </a>
            </li>
            <li role="presentation">
              <a
                style={{ paddingLeft: "35px" }}
                className="article__c"
                href="#messages"
                aria-controls="article"
                role="tab"
                data-toggle="tab"
                onClick={handleShowArticle}
              >
                <img src={img3} alt="img" />
              </a>
            </li>
          </ul>
        </div>

        <div className="update__textinput d-flex align-items-top">
          <div className="profile__btn__content dropdown">
            <Link
              to={`/${lang}/${t("home.profile")}/${String(
                me.id
              ).toLocaleLowerCase()}`}
              className="profile__btn user"
            >
              <Avatar user={me} alt={user.current_user.display_name} />
            </Link>
          </div>
          <div className="text__update">
            <form method="post" onSubmit={handleSubmit}>
              {error && (
                <div className="form-label-group">
                  <div className="alert alert-danger text-center">
                    {t(`errors.${error}`)}
                  </div>
                </div>
              )}

              <div className="form-label-group">
                <textarea
                  id="something"
                  name="something"
                  placeholder={t("feed.something-textarea")}
                  value={something}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="form-label-group m-0 text-right">
                {submit && !loading ? (
                  <button type="submit" className="btn btn-secondary px-4 py-2">
                    {t("createArticle.button")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="px-4 py-2"
                    disabled
                    style={{ background: "#D9D9D9", color: "white" }}
                  >
                    {t("createArticle.button")}
                  </button>
                )}

                {loading && <Spinner />}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  const { user, me } = state.authentication;
  return { user, me };
}

const actionCreators = {
  showAddArticle: modalActions.showAddArticle,
};

const connectedPostSomething = connect(mapState, actionCreators)(PostSomething);
export { connectedPostSomething as PostSomething };
