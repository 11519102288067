import React, { useEffect, useState } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";
import { useParams } from "@reach/router";
import { vendorService } from "../_services";
import { path, url } from "../_helpers/decoupled-url";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import location from "../_images/ico-location.png";
import ico_toolbar from "../_images/ico-share-toolbar.svg";

import web from "../_images/icons/web.png";
import phone from "../_images/icons/phone.png";
import email from "../_images/icons/email.png";

const InternalVendorPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const param = useParams();
  const [vendor, setVendor] = useState([]);
  const [shareUrl, setShareUrl] = useState("");
  const [social, setSocial] = useState([]);

  const { slug } = param;

  useEffect(() => {
    vendorService
      .getVendor(lang, slug)
      .then((result) => {
        setVendor(result.rows[0]);
        setShareUrl(path(url(result.rows[0].url)));
        setSocial(result.rows[0].custom_social_newtwork);
      })
      .catch(console.log);
  }, [lang, slug]);

  let newUrl = shareUrl.replace('fined', '');
  newUrl += `/${lang}/internal-vendor/${slug}`;

  const clickShares = (e) => {
    e.preventDefault();
  };

  const { custom_social_newtwork } = vendor;

  console.log(custom_social_newtwork);

  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("vendors.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="container">
          <section className="internal-vendor-head d-flex justify-content-center align-items-center">
            <div className="internal-vendor-image">
              <img src={vendor.field_photo} alt="internal-vendor-img" />
            </div>
            <div className="internal-vendor-text d-flex flex-column">
              <h1>{vendor.title}</h1>
              <label className="slogan mb-4">{vendor.field_slogan}</label>
              <label className="category mb-4">
                {vendor.field_services_vendors}
              </label>
              <div className="location d-flex align-items-center justify-content start mb-4">
                <img src={location} alt="ico-location" />
                <p className="location m-0">{vendor.field_country}</p>
              </div>
              {custom_social_newtwork !== undefined && (
                <ul className="socials d-flex align-items-center">
                  {custom_social_newtwork.map((item, index) => (
                    <li key={index}>
                      <a href={item.uri} target="_blank">
                        <img src={item.icono} alt="red-social" />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
          <section className="vendor-shared">
            <div className="detail__header__container__a">
              <div className="details__share">
                <div className="interesting__btn__alt align-items-center others__links dropdown feed">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={clickShares}
                  >
                    <img src={ico_toolbar} alt="Reply to comment" />
                    <span>{t("contents.share")}</span>
                  </a>
                  <ul
                    className="share__options dropdown-list"
                    id="shared__list"
                  >
                    <div className="share__links">
                      <WhatsappShareButton url={newUrl}>
                        <p>
                          <i className="fab fa-whatsapp" aria-hidden="true"></i>
                          {t("shared.share")} Whatsapp
                        </p>
                      </WhatsappShareButton>
                      <FacebookShareButton url={newUrl}>
                        <p>
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                          {t("shared.share")} Facebook
                        </p>
                      </FacebookShareButton>
                      <LinkedinShareButton url={newUrl}>
                        <p>
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                          {t("shared.share")} Linkedin
                        </p>
                      </LinkedinShareButton>
                      <TwitterShareButton url={newUrl}>
                        <p>
                          <i className="fab fa-twitter" aria-hidden="true"></i>
                          {t("shared.share")} Twitter
                        </p>
                      </TwitterShareButton>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="internal-vendor-body">
            <div className="content_title_body">
              <p className="title_body">{vendor.field_title_body}</p>
            </div>
            <p className="body">{vendor.body}</p>
          </section>
          <section className="internal-vendor-footer">
            <div>
              <h1>
                <span className="title__highlight">Contact</span>
                us
              </h1>
            </div>
            <div className="vendor-contact">
              <div className="d-flex web">
                <img src={web} alt="ico-web" />
                <p>{vendor.field_sitio_web}</p>
              </div>
              <div className="d-flex phone">
                <img src={phone} alt="ico-phone" />
                <p>{vendor.field_phone}</p>
              </div>
              <div className="d-flex email">
                <img src={email} alt="ico-email" />
                <p>{vendor.field_correo}</p>
              </div>
            </div>
          </section>
        </section>
      </main>
      <MobileMenu selected="vendors" withFade={true} />
    </Layout>
  );
};

export default InternalVendorPage;
