import React from "react";
import { Link } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { url } from "../../_helpers";
import he from "he";

const CategoryLink = ({ config, name, className, arr }) => {
  //Language hooks
  const { i18n } = useTranslation("common");
  const lang = i18n.language;

  if (config && name) {
    let category = config.categories[lang].find(
      (item) => item.category === he.decode(name)
    );
    //If any category was found.
    if (category) {
      return (
          <Link to={url(category.link)} className={className}>
          {category.category}
        </Link>
       
      );
    }
  }
  if (config !== undefined && arr !== "undefined" && arr !== '' && arr !== undefined) {
    const category = arr.map(function (e) {
      let category = config.categories[lang].find(
        (item) => e.includes(item.category)
      );
      return category;
    });

    // const listItems = category.map((item, id) => 
    // <Link to={url(item.link)} className={className} key={item.category}>
    //       {item.category}
    //     </Link>
    // );

    return (
      <div className="quick__cats d-flex flex-wrap">
        {/* {listItems} */}
      </div>
    );
   
  }

  return "";
};

function mapState(state) {
  const { config } = state.app;
  return { config };
}

const connectedCategoryLink = connect(mapState)(CategoryLink);
export { connectedCategoryLink as CategoryLink };
