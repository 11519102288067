import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

export default function VendorSearch({ article, onClick, hideSearch }) {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  return (
    <article
      itemScope=""
      itemType="http://schema.org/Article"
      className="col-12 col-sm-12 col-md-3 col-lg-3 vendors-search"
      onClick={onClick}
    >
      <div>
        <div className="row__article__right">
          <figure className="article__image__content">
            <Link to={`/${lang}/${t("url.internal-vendor")}/${article.nid}`}>
              <img
                itemProp="image"
                src={article.field_photo}
                alt={article.field_photo}
                onClick={hideSearch}
              />
            </Link>
          </figure>
          <div className="title-vendor">
            <h2 itemProp="headline">{article.title}</h2>
            <label>{article.field_services_vendors}</label>
            <p>{article.field_slogan}</p>
          </div>
        </div>
      </div>
    </article>
  );
}
