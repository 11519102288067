import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";

export default function CommunityAdSingle() {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [ads, setAds] = useState([]);

  useEffect(() => {
    /** Call Services  */
    appService
      .getAds(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setAds(response);
        }
      })
      .catch(console.log);
  }, [lang, setAds]);

  return (
    <div className="d-flex justify-content-center community-single">
      {ads.length > 0 && ads[0]?.field_imagen && ads[1]?.field_imagen && (
        <>
          <a
            href={ads[0].field_enlace_1}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ads[0].field_imagen} alt="Ad" />
          </a>
        </>
      )}
    </div>
  );
}
