import React from "react";
import { Link } from "@reach/router";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";

export default function NosotrosPagina() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  return (
    <Layout>
      <SEO lang={lang} title={t("pages.title")} />
      <main
        className="home__page"
        role="main"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="body__text about">
          <div className="container">
            <div className="frenetic__diference">
              <h2>¿Cuál es el ADN de un Tech Frenetic?</h2>
              <ul className="and__frenetic">
                <li>Nosotros somos neutrales.</li>
                <li>Somos apasionados de la tecnología.</li>
                <li>Somos amantes de la comunidad.</li>
              </ul>
              <Link
                to={`/${lang}/${t("url.community")}`}
                className="btn-primary"
              >
                Convertirse en Frenetic
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
