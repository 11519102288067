import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";

export default function CommunityAdSingleVideo() {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const [ads, setAds] = useState([]);
  const [showVideo, setShowVideo] = useState(true);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };
  

  useEffect(() => {
    /** Call Services  */
    appService
      .getAds(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setAds(response);
        }
      })
      .catch(console.log);
  }, [lang, setAds]);

return (
  <>
    {showVideo && ads.length > 0 && ads[2]?.field_imagen && ads[3]?.field_videos && (
      <div className="video-ads-container video-ads-single">
        <div className="close-button" onClick={toggleVideo}>X</div>
        <ReactPlayer
          url={ads[3].field_video || ads[3].field_videos}
          controls={true}
          width="100%"
          height="auto"
          playing={true}
        />
      </div>
    )}
  </>
);

}
