import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";
import { url, path } from "../../_helpers";

export default function HomeCarrousel() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState(null);

  useEffect(() => {
    /** Call Services  */
    appService
      .getHero(lang)
      .then((res) => res.json())
      .then((response) => {
        setSlides(response[0]);
      })
      .catch(console.log);
  }, [lang, setSlides]);

  //Loading
  if (!slides) {
    return null;
  }

  //Render
  return (
    <section className="home__slider__box">
      <div className="container d-flex align-items-center">
        <div className="col-lg-6 col-md-12 home__slider__txt">
          <h1
            dangerouslySetInnerHTML={{
              __html: slides.claim,
            }}
          ></h1>
          {slides.is_external === "False" ? (
            <Link to={url(slides.link)} className="btn-secondary white btn-lg">
              {slides.cta}
            </Link>
          ) : (
            <a
              href={slides.link}
              className="btn-secondary white btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              {slides.cta}
            </a>
          )}
        </div>
        <div className="col-md-6 d-none d-lg-block">
          <img itemProp="image" src={path(slides.image)} alt={slides.title} />
        </div>
      </div>
    </section>
  );
}
