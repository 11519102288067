import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";

export default function GroupLikeBox({ title, image, id, event, type }) {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const link = `/${lang}/${t("url.groups")}/${event}`;

  const [articles, setArticles] = useState([]);
  const [membersgroup, setMembersgroup] = useState([]);
  const navigate = useNavigate();

  const [submitJoin] = useState(false);


  useEffect(() => {
    /** Call Services  */
    // appService / este es el que debo usar
    //   .getMoreGroups(lang, 1)
    appService
      .getArticles(lang, event)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setArticles(response);
        }
      })
      .catch(console.log);
    // setArticles({
    //     "results": 1,
    // });
    appService
      .getMembersGroup(lang, event)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMembersgroup(response);
        }
      })
      .catch(console.log);
    // setMembersgroup({
    //   "results": 2,
    // });
  }, [lang, event]);

  const visitMe = (e) => {
    navigate(link);
  }

  return (
    <div className="groups__like__box">
      <div className="group__detail d-flex justify-content-center align-items-center flex-column">
        <div className="group__img">
          <Link to={link}>
            <img src={image} alt="Digital Nomads" />
          </Link>
        </div>
        <div className="groups__details">
          <Link to={link}>{title}</Link>
          <p>
            {membersgroup.results} {t("feed.group-members")}
          </p>
        </div>
        <div className="group__like__cta">
         
          {type === "Group Public" && (
            <button
              className="button-blue general-button"
              onClick={visitMe}
              style={{ color: "white" }}
            >
              {t("groups.visit_me")}
            </button>
          )}
          {type === "Group private" && (
            <button
              style={{ color: "white" }}
              className={`button-blue general-button  ${
                submitJoin && "button-gray"
              }`}
              onClick={visitMe}
            >
              {/* {submitJoin ? `${t("groups.pending")}` : `${t("groups.request")}`} */}
              {t("groups.visit_me")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
