import React, { useState, useEffect } from "react";
import SEO from "../_components/common/seo";
import Layout from "../_components/common/layout";
import { useTranslation } from "react-i18next";
import { MobileMenu } from "../_components/navigation/mobile-menu";


import ico_back from "../_images/ico-back.svg";
import no_publish from "../_images/no-published.png";
import CommunityMenu from "../_components/navigation/community-menu";
import GroupsMenu from "../_components/navigation/groups-menu";
import Credits from "../_components/common/credits";
import { seedServices } from "../_services";
import { SearchGroup } from "../_components/modals/searchGroup";
import { MembersCard } from "../_components/ui/members-card";
import CommunityAd2 from "../_components/ads/community-ad2";
import CommunityAdSingleVideo from "../_components/ads/community-ad-singleVideo";

const GeneralPage = () => {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [belong, setBelong] = useState([]);
  const [search, setSearch] = useState(true);

  useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem("me"));

    /** Call Services  */
    seedServices
      .getUserbelong(lang, id)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setBelong(response);
        }
      })
      .catch(console.log);

    // setArticles({
    //     "results": 1,
    // });
  }, [lang]);

  (() => {
    const inputSearch = document.querySelector("#query");
    if (!!inputSearch) {
      inputSearch.addEventListener("change", (e) => {
        if (e.target.value.trim().length > 2) {
          setSearch(false);
        } else {
          setSearch(true);
        }
      });
    }
  })();


  return (
    <Layout withFade={true} noFooter={true}>
      <SEO lang={lang} title={t("groups.title")} />
      <main
        className="groups"
        itemScope=""
        itemProp="mainContentOfPage"
        itemType="http://schema.org/Blog"
      >
        <section className="profile__page feed group">
          <div className="container d-flex flex-wrap">
            <div className="mobile__bar d-flex align-items-center">
              <a href="/" className="back__btn">
                <img src={ico_back} alt="Back" />
              </a>
              <span className="title__profile">{t("groups.ttl_mobile")}</span>
            </div>
            <div className="profile__menu col-lg-2 col-md-3">
              <CommunityMenu selected="groups" />
            </div>
            <div className="profile__body col-lg-10 col-md-9">
              <div className="groups__options">
                <div className="tabs__container groups__tabs group__menu__lv2">
                  <GroupsMenu selected="tab2" />
                </div>

                <div className="create__group__content ">
                  <h3 className="new__content group">
                    <span>{t("groups.ttl_blue")}</span>
                    {t("groups.ttl_black")}
                  </h3>

                  <SearchGroup />

                  {belong.length === 0 && (
                    <div className="creation__group__form no__groups">
                      <div className="profile__header no__published pt-0">
                        <span className="no__published d-block">
                          {t("groups.no_groups")}
                        </span>
                        <img
                          src={no_publish}
                          alt="You have no published articles yet"
                        />
                        <div className="buttons text-center">
                          <a
                            href={`/${lang}/${t("url.groups")}/${t(
                              "url.groups_new"
                            )}`}
                            className="btn btn-primary"
                          >
                            {t("groups.btn_create")}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {belong.length > 0 && search && (
                    <div className="creation__group__form max group-exist">
                      <div className="group__cards__container d-flex flex-wrap">
                        {belong.map((item, id) => (
                          <div
                            key={id}
                            className="group__card col-12 col-sm-12 col-md-4 col-lg-4"
                          >
                            <MembersCard  id={ item.id} label={item.label} field_logo={item.field_logo} type={item.type}/>
                            
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <CommunityAd2 />
              <CommunityAdSingleVideo />

              <Credits />
            </div>
          </div>
        </section>
      </main>
      <MobileMenu selected="community" withFade={true} />
    </Layout>
  );
};

export default GeneralPage;
