import React from "react";
import { eventService } from "../../_services";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

const RegisterEventForm = ({ user, event }) => {
  const { i18n } = useTranslation("common");
  const lang = i18n.language;
  const { name, custom_user_email, field_country } = user;


  const data = {
    field_id_event: Number(event),
    title: name,
    field_email: custom_user_email,
    field_state: field_country,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      eventService
        .registerEventForm(lang, data)
        .then((response) => console.log(response))
        .then(() => alert("usuario registrado"))
        .then(() => window.location.reload());
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-start flex-column mb-3">
          <label className="p-0">
            Full name<span style={{ color: "#FC3E39" }}>*</span>
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={name}
            readOnly
          />
        </div>
        <div className="d-flex justify-content-start flex-column mb-3">
          <label className="p-0">
            Email<span style={{ color: "#FC3E39" }}>*</span>
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={custom_user_email}
            readOnly
          />
        </div>
        <div className="d-flex justify-content-start flex-column mb-3">
          <label className="p-0">
            State<span style={{ color: "#FC3E39" }}>*</span>
          </label>
          <input
            type="text"
            name="country"
            className="form-control"
            value={field_country}
            readOnly
          />
        </div>

        <Button variant="primary" className="modal__button" type="submit">
          SignUp
        </Button>
      </form>
    </>
  );
};

export default RegisterEventForm;
