import React, { useEffect, useState } from "react";
import AuthorWall from "../contents/author-wall";
import he from "he";
import { PostReactions } from "./post-reactions";
import { PostActions } from "./post-actions";
import { PublishDate } from "./publish-date";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

import btnSave from "../../_images/ico-bookmark.svg";
import { articleService } from "../../_services";

export const PostQuote = ({ post, onDelete, user }) => {
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;
  const [listPostSave, setListPostSave] = useState([]);

  const { id } = JSON.parse(localStorage.getItem("me"));


  useEffect(() => {
    if (id) {
      articleService
        .getListSaveArticles(lang, id)
        .then((res) => res.json())
        .then((response) => {
          const articleIds = response.articles.map((article) => article.id);
          setListPostSave(articleIds);
        })
        .catch((error) => console.log(error));
    }
  }, [setListPostSave, id]);
  

  const handleSave = () => {
    articleService.postSaveArticle(post.id, id);
  }

  const isPostSaved = listPostSave.includes(post.id);

  return (
    <div className="feed__publication d-flex mb-4">
      <AuthorWall id={post.uid} name={post.display_name} date={post.date} />
      <article itemScope="" itemType="http://schema.org/Article">
        <div className="d-flex justify-content-between mb-3">
          <div
            className="name__author"
            itemProp="author"
            itemType="http://schema.org/Person"
          >
            <p>
              <Link
                to={`/${lang}/${t("home.profile")}/${String(
                  post.uid
                ).toLocaleLowerCase()}`}
                state={{ userA: { user } }}
              >
                {post.display_name}
              </Link>
            </p>

            <button
              className="btn-save_publication"
              title="save"
              onClick={handleSave}
              disabled={isPostSaved}
            >
              <img src={btnSave} alt="btn-save" />
            </button>
            <div className="details__autor d-flex">
              {user.role && (
                <>
                  <p className="position__name">{user.role}</p>
                  <span className="comment__autor__separator">•</span>
                </>
              )}
              <PublishDate className="comment__hour__tag" date={post.date} />
            </div>
          </div>
          {post.user === user && (
            <PostActions post={post} onDelete={onDelete} />
          )}
        </div>
        <div className="articles__activearea">
          <p className="post__text big__status">{he.decode(post.title)}</p>
        </div>

        <PostReactions
          type="field_like_post"
          post={post}
          actions={true}
          like={false}
        />
      </article>
    </div>
  );
};
