import React, { useState, useEffect } from "react";
import GroupLikeBox from "../ui/group-like-box";
import { useTranslation } from "react-i18next";
import { appService } from "../../_services";

export default function GroupsMayLike() {
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;
  const [mygroups, setMygroups] = useState([]);

  useEffect(() => {
    /** Call Services  */
    appService
      .getMoreGroups2(lang)
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMygroups(response);
        }
      })
      .catch(console.log);
  }, [lang, setMygroups]);

  return (
    <>
      <h3 className="followers__title list-group">{t("feed.group-title")}</h3>
      {mygroups.length > 0 && (
        <div className="list-group-feed d-flex align-items-center justify-content-center">
          {mygroups.map((item, id) => (
            <GroupLikeBox
              title={item.label}
              image={item.field_logo}
              event={item.id}
              key={id}
              type={item.type}
            />
          ))}
        </div>
      )}
    </>
  );
}
