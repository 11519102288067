import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { PublishDate } from "../ui/publish-date";
import { CategoryLink } from "../ui/category-link";
import { PostReactions } from "./post-reactions";
import { PostActions } from "./post-actions";
import { Stream } from "@cloudflare/stream-react";
import he from "he";
import AuthorWall from "../contents/author-wall";

import prueba from "../../_images/article-page.jpg";

export const PostOwned = ({ post, type, onDelete }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  return (
    <div className="feed__publication d-flex mb-4">
      <AuthorWall id={post.uid} name={post.display_name} date={post.date} />
      <article
        itemScope=""
        itemType="http://schema.org/Article"
        className="feed__byme"
      >
        <div className="d-flex justify-content-between mb-3">
          <div
            className="name__author"
            itemProp="author"
            itemType="http://schema.org/Person"
          >
            <p>
              <Link
                to={`/${lang}/${t("home.profile")}/${String(
                  post.uid
                ).toLocaleLowerCase()}`}
              >
                {post.display_name}
              </Link>
            </p>
            <div className="details__autor d-flex">
              <PublishDate className="article__hour__tag" date={post.date} />
            </div>
          </div>
          {type !== "activity" && (
            <PostActions post={post} onDelete={onDelete} />
          )}
          
        </div>
        <div className="article__information post">
          <h2 itemProp="headline">
            <Link to={`/${lang}/${post.id}`} state={{ post: { post } }}>
              {he.decode(post.title)}
            </Link>
          </h2>
        </div>
        {post.field_has_video === "True" ? (
          <Stream controls src={post.field_cloudflare_id} />
        ) : (
          <figure className="article__image__content">
            <Link
              className="image__btn"
              to={`/${lang}/${post.id}`}
              state={{ post: { post } }}
            >
              <img
                itemProp="image"
                src={prueba} //post.image
                alt={he.decode(post.title)}
              />
            </Link>
          </figure>
        )}

        <PostReactions
          type="field_like_dislike"
          post={post}
          qualification={true}
          actions={true}
        />
      </article>
    </div>
  );
};
