import React from "react";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

import arrowLeft from "../../_images/ico-arrow-blue.svg";
import arrowRight from "../../_images/ico-arrow-blue.svg";

export const SponsorsEvents = ({ sponsors }) => {
  const { t } = useTranslation("common");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        {index + 1}
      </li>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="a react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => onClick()}
      >
        <img src={arrowLeft} alt="left" />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="b react-multiple-carousel__arrow"
        onClick={() => onClick()}
      >
        <img src={arrowRight} alt="right" />
      </button>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center title_carusel_events">
        <div className="category__card">
          <h2>
            <span>{t("events.title")}</span>
            {t("events.sponsors")}
          </h2>
        </div>
      </div>

      <Carousel
        responsive={responsive}
        showDots={true}
        infinite={true}
        customDot={<CustomDot />}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {Array.isArray(sponsors) &&
          sponsors.map((item, index) => (
            <img src={item.field_image_sponsor} alt="sponsors" key={index} />
          ))}
      </Carousel>
    </>
  );
};
