import React from "react";
import ReactHtmlParser from "react-html-parser";

export default function EventDetail({
  text,
  media,
  categories,
  buttonDownload,
}) {
  //Language hooks

  return (
    <div className="article__body p-0">
      {ReactHtmlParser(text)}
    </div>
  );
}
