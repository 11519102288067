import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import * as moment from "moment";
import { useTranslation } from "react-i18next";

import EventThumb from "../../_components/ui/event-thumb";
import { eventService } from "../../_services/event.service";
import { EventsForm } from "../forms/events-form";
import { path } from "../../_helpers";

export default function UpcomingEvents() {
  //Language hooks
  const { t, i18n } = useTranslation("common");
  const lang = i18n.language;

  const [data, setData] = useState([]);

  useEffect(() => {
    /** Call Services  */
    eventService
      .getEventsUpcommig(lang)
      .then((res) => res.json())
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [lang, setData]);

  const handleSearchSubmit = (filters) => {
    let query = "";
    Object.keys(filters).map((idx) => {
      let param = "";
      if (filters[idx]) {
        //Translate
        switch (idx) {
          case "q":
            param = t("vendors.query_what");
            break;
          case "d":
            param = t("vendors.query_where");
            break;
          case "c":
            param = t("vendors.query_category");
            break;
          case "b":
            param = t("vendors.query_brand");
            break;
          default:
            param = "";
        }
        if (param) {
          query += !query
            ? `?${idx}=${filters[idx]}`
            : `&${idx}=${filters[idx]}`;
        }
      }
      return query;
    });

    if (query) {
      navigate(`/${lang}/${t("url.events")}/${t("url.searchup")}${query}`);
    }
  };

  return (
    <div className="content">
      <div className="featured__heading d-flex justify-content-between">
        <h3 className="title__home bold">
          <span className="title__highlight">{t("events.upcoming_blue")}</span>
          {t("events.upcoming_black")}
        </h3>
      </div>
      <EventsForm onSubmit={handleSearchSubmit} />
      <div className="featured__allitems">
        {data.map((dataArticle) => (
          <div
            className="small__column__articles"
            key={dataArticle.nid}
          >
            <EventThumb
              slug={dataArticle.field_path}
              img={path(dataArticle.field_image)}
              title={dataArticle.field_name_evente}
              location={dataArticle.field_location}
              startDate={moment(dataArticle.field_start_date).format(
                "MMM DD, YYYY"
              )}
              category={dataArticle.field_principal_category}
              description={dataArticle.field_summary_short}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
