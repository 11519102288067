import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PublishDate } from "../ui/publish-date";
import AuthorFace from "./author-face";
import * as moment from "moment";
import he from "he";

import interesting_counter from "../../_images/ico-interesting-counter.svg";
import interesting_icon from "../../_images/ico-interesting.svg";
import interesting_no_icon from "../../_images/ico-interesting-no.svg";
import comments_icon from "../../_images/ico-comments.svg";
import comment_delete from "../../_images/ico-delete-mycontent.svg";
import { Spinner } from "../ui/spinner";
import { Avatar } from "../users/avatar";
import { commentService, communityService, userService } from "../../_services";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../css/post-actions.css";
import icon from "../../_images/favicon.svg";

const Comment = ({ id, me, comment, depth, fetchComments }) => {
  //Language hooks
  const { i18n, t } = useTranslation("common");
  const lang = i18n.language;

  //Comments state
  const [user, setUser] = useState({});
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(Number("0" + comment.likes));
  const [liking, setLiking] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [favorite, setFavorite] = useState([]);

  const [replys, setReplys] = useState(0);
  const [reply, setReply] = useState("");
  const [doreply, setDoreply] = useState(false);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [replyBtn, setReplyBtn] = useState(false);
  const [userResp, setUserResp] = useState(false);
  const [userReply, setUserReply] = useState(false);
  const [replyComment, setReplyComment] = useState("");
  let lk = [];

  /**
   *
   */

  useEffect(() => {
    userService
      .favorites(me.id)
      .then((response) => {
        if (response) {
          const items = response.map(function (e) {
            let element = e.entity;
            return element;
          });
          setFavorite(items);
          lk.push(items);
        }
      })
      .then(() => {
        if(lk[0].includes(comment.id)){
          setLiked(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [me, comment]);

  const getComments = async () => {
    commentService
      .getReplys(lang, id, comment.id)
      .then((response) => {
        setComments(comments.concat(response));
        setReplys(response.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComments();
  }, [comment]);

  const handleDelete = (e) => {
    e.preventDefault();
    setDeleteBtn(true);

    const commentList = e.target;
    setUserResp(commentList.closest(".comment__li"));
  };

  const replyDelete = (e, id) => {
    setReplyComment(id);
    setReplyBtn(true);
    setUserReply(e.closest(".comment-reply-item"));
  };

  const onHandleUserData = (info) => {
    setUser(info);
  };

  const handleLike = (e) => {
    if (!liking) {
      setLiking(true);
      if (!liked) {
        //LIKE
        communityService
          .like(comment.id, "comment", "field_like_comment")
          .then((result) => {
            setLikeId(result.id[0].value);
            setLiked(true);
            setLikes(likes + 1);
            setLiking(false);
          })
          .catch((err) => {
            setLiking(false);
          });

        //DISLIKE
      } else {
        communityService
          .dislike(likeId)
          .then((result) => {
            setLikeId(0);
            setLiked(false);
            setLikes(likes - 1);
            setLiking(false);
          })
          .catch((err) => {
            setLiking(false);
          });
      }
    }

    e.preventDefault();
  };

  const handleReply = (e) => {
    e.preventDefault();
    setDoreply(!doreply);
  };

  const handleComments = (e) => {
    e.preventDefault();
  };

  /**
   *
   * @param {*} e
   */
  const handleChange = (e) => {
    setReply(e.target.value);
  };

  /**
   *
   * @param {*} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (String(reply).trim() !== "") {
      setLoading(true);
      commentService
        .addReply(lang, id, reply, comment.id)
        .then((response) => {
          const data = {
            id: response.cid[0].value,
            body: response.comment_body[0].value,
            date: moment(response.created[0].value).format(
              "YYYY-MM-DD hh:mm:ss"
            ),
            likes: "0",
            author: me.id,
            user: me.name,
          };

          comments.unshift(data);
          setComments(comments);
          setLoading(false);
          setReply("");
          setDoreply(false);
          setReplys(replys + 1);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const hiddenModal = () => {
    setDeleteBtn(false);
    setReplyBtn(false);
  };

  const deleteComment = () => {
    // const comment__container = document.querySelector(".comments__list ul");
    // const comment_li = document.querySelector(".comment__li");
    let userComment = userResp;

    communityService
      .deleteComment(comment.id)
      .then(() => {
        fetchComments();
        userComment.remove();
      })
      .then(() => setDeleteBtn(false))
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  };

  const deleteReply = () => {
    // const comment__container = document.querySelector(".comment-reply-list");
    // const comment_li = document.querySelector(".comment-reply-item");
    let userReplyComment = userReply;
    communityService
      .deleteComment(replyComment)
      .then(() => {
        fetchComments();
        userReplyComment.remove();
      })
      .then(() => setReplyBtn(false))
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="comment__container d-flex">
        {/* Avatar */}
        <div className="profile__image">
          <AuthorFace id={comment.author} onData={onHandleUserData} />
        </div>

        {/* Gray box with text */}
        <div className="comment__text">
          <div className="comment__content">
            {/* Author and time */}
            <div className="comment__heading">
              <div className="comment__autor row">
                <p className="comment__name">
                  <Link
                    to={`/${lang}/${t("home.profile")}/${String(
                      user.name
                    ).toLocaleLowerCase()}`}
                  >
                    {user.display_name}
                  </Link>
                </p>
                <span className="comment__autor__separator">
                  • {comment.user}{" "}
                </span>
                <PublishDate
                  className="article__hour__tag"
                  date={comment.date}
                />
              </div>
            </div>

            {/* Comment */}
            <div className="comment__content__inner">
              {he.decode(comment.body)}
            </div>

            {/* Mobile reactions */}

            <div className="reactions__counter responsive d-flex justify-content-start">
              <div className="interesting__counter align-items-center">
                <a
                  href="/"
                  className="d-flex align-items-center"
                  onClick={handleLike}
                >
                  <img src={interesting_counter} alt="Mark as cool" />
                  {/* <span>{likes}</span> */}
                </a>
              </div>
              <span className="article__cat__separator">•</span>
              <div className="comment__counter align-items-center">
                <a
                  href="/"
                  className="d-flex align-items-center"
                  onClick={handleComments}
                >
                  <span>{replys} Comments</span>
                </a>
              </div>
            </div>
          </div>

          {depth < 1 && (
            <div className="reactions__functions d-flex justify-content-between">
              {/* Desktop reactions */}
              <div className="comments__btns d-flex justify-content-between align-items-center">
                <div className="interesting__btn align-items-center">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={handleLike}
                  >
                    {/* {liked ? (
                      <img src={interesting_no_icon} alt="Mark as not cool" />
                    ) : (
                      <img src={interesting_icon} alt="Mark as cool" />
                    )} */}
                    {liked ? (
                      <img src={interesting_no_icon} alt="Mark as not cool" />
                    ) : (
                      <img src={interesting_icon} alt="Mark as cool" />
                    )}
                    <span>{liked ? "Cool" : "Cool"}</span>
                  </a>
                </div>

                <div className="reply__btn align-items-center">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={handleReply}
                  >
                    <img src={comments_icon} alt="Reply to comment" />
                    <span>Reply</span>
                  </a>
                </div>

                {me.name === comment.user && (
                  <div className="delete__btn align-items-center">
                    <a
                      href="/"
                      className="d-flex align-items-center"
                      onClick={handleDelete}
                    >
                      <img src={comment_delete} alt="Reply to comment" />
                      <span>Delete</span>
                    </a>
                  </div>
                )}
              </div>

              {/* Desktop reactions sumary */}
              <div className="reactions__counter d-flex justify-content-between">
                {likes > 0 && (
                  <div className="interesting__counter align-items-center">
                    <a
                      href="/"
                      className="d-flex align-items-center"
                      onClick={handleLike}
                    >
                      <img src={interesting_counter} alt="Mark as cool" />
                      <span>{likes}</span>
                    </a>
                  </div>
                )}

                {likes > 0 && (
                  <span className="article__cat__separator">•</span>
                )}

                <div className="comment__counter align-items-center">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={handleComments}
                  >
                    <span>{replys} Comments</span>
                  </a>
                </div>
              </div>
            </div>
          )}

          {/* Form to comment */}
          {doreply && (
            <div className="new__comment d-flex align-items-center">
              <div className="profile__image">
                <Avatar user={me} alt={me.display_name} />
              </div>
              <div className="form__comment">
                <form className="d-flex" onSubmit={handleSubmit}>
                  <input
                    id="myComment"
                    name="myComment"
                    type="text"
                    placeholder={t("contents.write")}
                    value={reply}
                    onChange={handleChange}
                  />

                  <button type="submit">
                    {loading ? (
                      <Spinner size="small" />
                    ) : (
                      t("contents.ttl_comment")
                    )}
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Subcomments*/}
      {comments.length > 0 && (
        <ul className="comment-reply-list">
          {comments.map((item) => (
            <li key={item.id} className="comment-reply-item">
              <ConnectedComment id={id} comment={item} depth={depth + 1} />
              {me.name === item.user && (
                <div className="delete__btn align-items-center">
                  <a
                    href="/"
                    className="d-flex align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      replyDelete(e.target, item.id);
                    }}
                  >
                    <img src={comment_delete} alt="Reply to comment" />
                    <span>Delete</span>
                  </a>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}

      {deleteBtn && (
        <Modal
          backdrop="static"
          show={deleteBtn}
          keyboard={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Modal.Header
            style={{ border: "none", width: "70%", margin: "0 auto" }}
          ></Modal.Header>
          <Modal.Body style={{ margin: "0 auto", width: "70%" }}>
            <div className="tittle__premium d-flex justify-content-center align-items-center">
              <img src={icon} alt="icon" />
              <h5>{t("delete.textComment")}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={deleteComment}
                className="me-5 button__delete"
              >
                {t("delete.yes")}
              </Button>
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={hiddenModal}
                className="me-5  button__delete"
              >
                {t("delete.not")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {replyBtn && (
        <Modal
          backdrop="static"
          show={replyBtn}
          keyboard={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Modal.Header
            style={{ border: "none", width: "70%", margin: "0 auto" }}
          ></Modal.Header>
          <Modal.Body style={{ margin: "0 auto", width: "70%" }}>
            <div className="tittle__premium d-flex justify-content-center align-items-center">
              <img src={icon} alt="icon" />
              <h5>{t("delete.replyComment")}</h5>
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={deleteReply}
                className="me-5 button__delete"
              >
                {t("delete.yes")}
              </Button>
              <Button
                style={{ fontSize: "15px", width: "30%" }}
                variant="primary"
                onClick={hiddenModal}
                className="me-5  button__delete"
              >
                {t("delete.not")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

function mapState(state) {
  const { me, user: token } = state.authentication;
  return { me, token };
}

const ConnectedComment = connect(mapState)(Comment);
export { ConnectedComment as Comment };
