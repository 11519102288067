import React from "react";
import { connect } from "react-redux";

const HorizontalAd = ({ id, banners }) => {
  if (!banners || !id) {
    return "";
  }

  const banner = banners.filter((item) => item.name === id);
  if (!banner[0]) {
    return "";
  }
  return (
    <section className="hotizontal ad">
      <div
        className="container"
        dangerouslySetInnerHTML={{
          __html: banner[0].script,
        }}
      ></div>
    </section>
  );
};

function mapState(state) {
  const { banners } = state.app;
  return { banners };
}

const connectedHorizontalAd = connect(mapState, null)(HorizontalAd);
export { connectedHorizontalAd as HorizontalAd };
